import React from 'react';
import './SingleShop.scss';
import {baseUrl, mobileResolution, pcResolution} from '../../vendor/constants';
import {Link} from 'react-router-dom';
import shopPlaceholder from '../../vendor/img/store-placeholder.png'
import {TShop} from "../../redux/types";

type TSingleShop = {
    card: TShop
}

const SingleShop: React.FC<TSingleShop> = ({card}) => {

    const shopImage = card.logo ? baseUrl + card.logo.thumb : shopPlaceholder

    return (
        <div className="singleShop">
            <Link to={`/shop/${card.slug}`}>
                <div className="singleShop__image-container">
                    {card.has_collection &&
                      <span className="singleShop__collection singleShop__tag_black">новая коллекция</span>}
                    {card.limited_action_text ? <div className="singleShop__tags-block"><span
                            className="singleShop__tag_white">{card.limited_action_text}</span></div>
                        :
                        card.discount_percent && <div className="singleShop__tags-block">
                          {card.has_collection && <span className="singleShop__tag_white">распродажа</span>}
                        <span className="singleShop__tag_white">скидки до -{card.discount_percent}%</span>
                      </div>
                    }
                    <img
                        srcSet={`${baseUrl + card?.logo?.src_m} ${mobileResolution}, ${baseUrl + card?.logo?.src} ${pcResolution}`}
                        alt={`Логотип магазина ${card.name}`} className="singleShop__image" src={shopImage}/>
                </div>
            </Link>
            <div className="singleShop__title">{card.name}</div>
            <div
                className="singleShop__tags">{card.categories[0]?.name}</div>
            <div>
                <div className="singleShop__map-container">
                    <div>{card.floor} этаж</div>
                    <Link className="singleShop__link"
                          to={`/shops/map?dataspace=${card.map?.dataspace}&floor=${card.floor}`}>Показать на
                        карте</Link>
                </div>
            </div>
        </div>
    );
};

export default SingleShop;
