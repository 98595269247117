import React, {useRef} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper";
import './SwiperMain.scss'
import {baseUrl, mobileResolution, pcResolution} from "../../vendor/constants";
import {useGetBannersQuery} from "../../redux/otherApi";
import {Swiper as SwiperCore} from 'swiper/types';


const SwiperMain = () => {
    const swiperRef = useRef<SwiperCore>();

    const {data: banners} = useGetBannersQuery()


    return (
        <Swiper
            onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
            }}
            autoplay={{delay: 3000}}
            pagination={{
                clickable: true,
                bulletClass: 'swiperMain__pagination-bullet',
                bulletActiveClass: 'swiperMain__pagination-bullet-active',
                horizontalClass: 'swiperMain__pagination-container',
            }}
            modules={[Pagination, Autoplay]}
            slidesPerView={1} className="swiperMain">
            {banners?.map(banner => {
                return (
                    <SwiperSlide
                        key={banner.image.thumb}
                        className="swiperMain__slide">
                        <a className="swiperMain__link" href={banner.link}>
                            <picture>
                                <source srcSet={baseUrl + banner.image.src_m} media="(max-width: 500px)"/>
                                <img className="swiperMain__image" alt={banner.name} src={baseUrl + banner.image.src}/>
                            </picture>

                        </a>
                    </SwiperSlide>
                )
            })}
            <button className="swiperMain__navigation swiperMain__prev"
                    onClick={() => swiperRef.current?.slidePrev()}>
            </button>
            <button className="swiperMain__navigation swiperMain__next"
                    onClick={() => swiperRef.current?.slideNext()}>
            </button>
        </Swiper>
    );
};

export default SwiperMain;
