import React from 'react';
import {Navigate, useLocation} from "react-router-dom";

type TRequireAuth = {
    children: JSX.Element
}

const RequireAuth:React.FC<TRequireAuth> = ({ children }) => {
    const token = localStorage.getItem('token')
    const location = useLocation()

    if (!token) {
        return <Navigate to="/" state={{ from: location }} replace />
    }

    return children
};

export default RequireAuth;