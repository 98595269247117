import React, {Dispatch, SetStateAction} from 'react';
import {FormikProps} from "formik";
import close from "../../../../vendor/img/close.svg";
import {TUpdateUserInfo} from "../ChangeInfo";

type TCancelEmailPopup = {
    closePopup: () => void
    openedPopup: boolean
    setOpenGenericPopup: Dispatch<SetStateAction<boolean>>
    formik: FormikProps<TUpdateUserInfo>
}

const CancelEmailPopup: React.FC<TCancelEmailPopup> = ({
    closePopup, openedPopup, setOpenGenericPopup, formik
                                                       }) => {
    const acceptHandler = () => {
        formik.setFieldTouched('sendEmailReasonText', true, true)
        if (!formik.errors.sendEmailReasonText) {
            closePopup()
            setOpenGenericPopup(true)
        }
    }

    return (
        <div className={openedPopup ? "cancelSmsPopup cancelSmsPopup_visible" : "cancelSmsPopup"}>
            <div className="cancelSmsPopup__content">
                <img alt="Крестик" className="cancelSmsPopup__close" src={close} onClick={closePopup}/>
                <h3 className="cancelSmsPopup__title">Я больше не хочу получать информацию о скидках, акциях и
                    мероприятиях, потому что:</h3>
                <div className="cancelSmsPopup__checkboxes-container">
                    <label className="changeInfo__checkbox-label">
                        <input className="changeInfo__checkbox"
                               type="checkbox"
                               name="sendEmailReasons"
                               value="Информация мне больше не интересна"
                               onChange={formik.handleChange}
                        />Информация мне больше не интересна</label>
                    <label className="changeInfo__checkbox-label">
                        <input className="changeInfo__checkbox"
                               type="checkbox"
                               name="sendEmailReasons"
                               value="навязчиво - слишком много писем"
                               onChange={formik.handleChange}
                        />навязчиво - слишком много писем</label>
                    <label className="changeInfo__checkbox-label">
                        <input className="changeInfo__checkbox"
                               type="checkbox"
                               name="sendEmailReasons"
                               value="больше не посещаю эти магазины"
                               onChange={formik.handleChange}
                        />больше не посещаю эти магазины</label>
                </div>
                <textarea name="sendEmailReasonText"
                          value={formik.values.sendEmailReasonText}
                          onChange={formik.handleChange}
                          placeholder="Причина"
                          className="cancelSmsPopup__textarea"/>
                {formik.touched.sendEmailReasonText && formik.errors.sendEmailReasonText &&
                  <div className="loginForm__error">{formik.errors.sendEmailReasonText}</div>}
                <button type="button" onClick={acceptHandler}
                        className="cancelSmsPopup__button">Подтвердить
                </button>
            </div>
        </div>
    );
};

export default CancelEmailPopup;