import React from 'react';
import './ChoosenGallery.scss'
import {baseUrl} from "../../vendor/constants";
import {TImage} from "../../redux/types";

type TChoosenGalleryGrid = {
    photos: TPhoto[] | undefined
}

type TPhoto = {
    image: TImage
    main: number
}

const ChoosenGalleryGrid:React.FC<TChoosenGalleryGrid> = ({ photos }) => {

    return (
        <div className="choosenGallery__grid">
            {photos?.map(photo => {
                return (
                    <div key={photo.image.src} className="choosenGallery__item">
                        <img className="choosenGallery__img" src={baseUrl + photo.image.src} />
                    </div>
                )
            })}

        </div>
    );
};

export default ChoosenGalleryGrid;
