import {createSlice} from '@reduxjs/toolkit';

export type Tsale = {
    amount: number
    date: string
    discountAmount: number
    shop: string
}

export type TuserInfo = {
    address: string
    birthdate: string
    cardExpirationDate: string
    cardNumber: string
    currentSavings: number
    discountLevel: number
    email: string
    firstname: string
    lastname: string
    middlename: string
    phone: string
    recentSales: Tsale[]
    sendEmail: boolean
    sendSms: boolean
    totalSavings: 0
    gender: number | null
    maritalStatus: number | null
    childrenCount: string | null
    childrenNames: string | null
}

type TAuthSliceState = {
    userInfo: TuserInfo | null
}

const initialState: TAuthSliceState = {
    userInfo: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signOut(state) {
            localStorage.removeItem('token')
            state.userInfo = null
        },
        getUserInfo(state, action) {
            state.userInfo = action.payload
        }
    }
})

export const {signOut, getUserInfo} = authSlice.actions

export default authSlice.reducer