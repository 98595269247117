import React from 'react';
import {Tsale} from "../../../../redux/authSlice";

type TPurchase = {
    purchase: Tsale
}

const Purchase: React.FC<TPurchase> = ({ purchase }) => {

    const purchaseDate = new Date(purchase.date)
    const year = purchaseDate.getFullYear()
    const month = ("0" + (purchaseDate.getMonth() + 1)).slice(-2)
    const day = purchaseDate.getDate()

    return (
        <div>
            <div className="info__last-purchases-item">
                <div>{day}.{month}.{year}</div>
                <div>{purchase.shop}</div>
                <div>{purchase.amount}</div>
            </div>
        </div>
    );
};

export default Purchase;