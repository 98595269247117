import React from 'react';
import {FormikHelpers, useFormik} from "formik";
import './LoginForm.scss'
import {PersonSignIn, validationSignIn} from "../../../utils/validation";
import {useNavigate} from "react-router-dom";
import {useGetUserInfoQuery, useSignInMutation} from "../../../redux/authApi";

type TLoginForm = {
    onClose: () => void
    phoneValue: string
    setPhoneValue: React.Dispatch<React.SetStateAction<string>>
}

const LoginForm: React.FC<TLoginForm> = ({onClose, phoneValue, setPhoneValue}) => {
    const navigate = useNavigate();
    const [signIn, {isLoading}] = useSignInMutation()
    const token = localStorage.getItem('token')
    useGetUserInfoQuery(undefined, {skip: !token})

    const onSubmitForm = async (values: PersonSignIn, actions: FormikHelpers<PersonSignIn>) => {
        let formData = new FormData();
        for (let value in values) {
            formData.append(value, String(values[value as keyof PersonSignIn]))
        }
        try {
            const response = await signIn(formData).unwrap()
            localStorage.setItem('token', response.token)
            navigate('/my-profile', {replace: true})
            onClose()
        } catch (e: any) {
            if (e.data.error === "wrong-password") {
                return actions.setStatus("Неправильный логин или пароль")
            }
            else if (e.data.error === "user-inactive") {
                return actions.setStatus("Аккаунт деактивирован")
            }
            else if (e.data.error === "user-blocked") {
                return actions.setStatus("Аккаунт заблокирован")
            }
            else if (e.data.error === "card-notfound" || e.data.error === "crmid-notfound") {
                return actions.setStatus("У пользователя нет привязанной карты")
            }
            else if (e.data.error === "user-notfound") {
                return actions.setStatus("Пользователь не найден")
            } else {
                return actions.setStatus('error')
            }
        }
    }

    const formik = useFormik<PersonSignIn>({
        initialValues: {
            phone: phoneValue,
            password: ''
        },
        validationSchema: validationSignIn,
        onSubmit: async (values, actions) => {
            await onSubmitForm(values, actions)
        }
    })

    formik.values.phone = phoneValue

    return (
        <>
            <form className="loginForm" onSubmit={formik.handleSubmit}>
                <div className="loginForm__input-container">
                    <label className="loginForm__label" htmlFor="phone">Введите номер телефона</label>
                    <input className="loginForm__input"
                           placeholder="89123456789"
                           name="phone"
                           type="text"
                           onChange={(e) => {
                               formik.setFieldValue("phone", e.target.value);
                               setPhoneValue(e.target.value)
                           }}
                           onBlur={formik.handleBlur}
                           value={formik.values.phone}/>
                    {formik.touched.phone && formik.errors.phone &&
                      <div className="loginForm__error">{formik.errors.phone}</div>}
                </div>
                <div className="loginForm__input-container">
                    <label className="loginForm__label" htmlFor="lastname">Введите пароль</label>
                    <input className="loginForm__input"
                           placeholder="Пароль"
                           id="lastname"
                           name="password"
                           type="password"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           value={formik.values.password}/>
                    {formik.touched.password && formik.errors.password &&
                      <div className="loginForm__error">{formik.errors.password}</div>}
                    {!!formik.status && <div className="loginForm__error">{formik.status}</div>}
                </div>
                <button disabled={formik.isSubmitting} className="loginForm__button" type="submit">{isLoading ? "Загрузка..." : "Войти"}</button>
            </form>
        </>
    );
};

export default LoginForm;
