import React from 'react';
import './EventsPage.scss';
import {Link} from "react-router-dom";
import EventsCard from "../EventsCard/EventsCard";
import {useGetEventsQuery} from "../../redux/eventsApi";
import Preloader from "../Preloader/Preloader";
import NotFoundPage from "../NotFoundPage/NotFoundPage";


const EventsPage = () => {
    const { data: events, isFetching, isError } = useGetEventsQuery()

    if (isFetching) return <Preloader />

    if (isError) return <NotFoundPage />

    return (
        <div className="promotionsGrid">
            <div className="bread-crumbs">
                <Link to="/" className="bread-crumbs-link">Главная</Link>
                <Link to="/events" className="bread-crumbs-link" >Все события</Link>
            </div>
            <div className="promotionsGrid__grid">
                {events?.map((event) => {
                    return (
                        <EventsCard
                            event={event}
                            key={event.slug}
                        />

                    )
                })}
            </div>
        </div>
    );
};

export default EventsPage;