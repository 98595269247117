import React from 'react';
import Promotions from '../Promotions/Promotions';
import Events from '../Events/Events';
import MainSection from "../MainSection/MainSection";
import PhotoGallery from "../PhotoGallery/PhotoGallery";

type TMainPage = {
    windowWidth: number
}

const MainPage: React.FC<TMainPage> = ({windowWidth}) => {
    return (
        <>
            <MainSection/>
            <Promotions/>
            <Events/>
            <PhotoGallery windowWidth={windowWidth}/>
        </>
    );
}

export default MainPage;
