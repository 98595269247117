import React from 'react';
import {baseUrl} from "../../vendor/constants";
import {Link} from "react-router-dom";
import {TPhotoGallery} from "../../redux/types";

type TPhotoGalleryItem = {
    gallery: TPhotoGallery
}

const PhotoGalleryItem: React.FC<TPhotoGalleryItem> = ({ gallery }) => {

    const mainImage = gallery.pictures.find(pic => pic.main === 1)
    const renderMainImage = mainImage ? mainImage : gallery.pictures[0]
    const otherImages = gallery.pictures.filter(pic => pic.image !== renderMainImage.image)

    return (
        <div className="photoGalleriesPage__item">
            <div className="photoGalleriesPage__images-container">
                <div className="photoGalleriesPage__main-image">
                    <img src={baseUrl + renderMainImage.image.thumb} className="photoGalleriesPage__img"/>
                </div>
                <div className="photoGalleriesPage__other-images">
                    {otherImages.map(image => {
                        return (
                            <div key={image.image.thumb} className="photoGalleriesPage__other-image">
                                <img src={baseUrl + image.image.thumb} className="swiperPhotoGallery__img"/>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="photoGalleriesPage__description-container">
                <h3 className="photoGalleriesPage__title">{gallery.name}</h3>
                <p className="photoGalleriesPage__date">{gallery.date}</p>
            </div>
            <Link className="photoGalleriesPage__link" to={`/photos/${gallery.slug}`}>Подробнее</Link>
        </div>
    );
};

export default PhotoGalleryItem;
