import {emptySplitApi} from "./emptySplitApi";
import {TPromotion} from "./types";

type TGetArchivedPromotions = {
    promotions: TPromotion[]
    totalPagesCount: number
}

export const promotionsApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getPromotions: build.query<TPromotion[], void>({
            query: () => 'cgch/action?active=1'
        }),
        getSinglePromotion: build.query<TPromotion, string | undefined>({
            query: (promoUrl) => `cgch/action/view/${promoUrl}`
        }),
        getArchivedPromotions: build.query<TGetArchivedPromotions, number>({
            query: (page = 1) => `cgch/action?page=${page}&archived=1`,
            transformResponse: (response: TPromotion[], meta: any) => ({
                promotions: response,
                totalPagesCount: Number(meta?.response?.headers.get('x-pagination-page-count'))
            })
        }),

    }),
    overrideExisting: false,
})

export const { useGetPromotionsQuery, useGetSinglePromotionQuery, useGetArchivedPromotionsQuery } = promotionsApi