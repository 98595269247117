import React from 'react';
import {english, russian} from '../../vendor/alphabetFilter';
import AlphabetLink from '../../routerLinks/AlphabetLink';
import {Link, useSearchParams} from 'react-router-dom';
import "./ShopsAlphabetFilter.scss";
import {useAppDispatch} from "../../redux/store";

const ShopsAlphabetFilter: React.FC = () => {

    const dispatch = useAppDispatch();

    let [searchParams] = useSearchParams();
    let isActive = searchParams.get("letter") === "all";

    const activeClassName = 'shopsCategoriesFilter__row_active shopsCategoriesFilter__row'
    const inactiveClassName = 'shopsCategoriesFilter__row'

    return (
        <div className="shopsCategoriesFilter">
            <Link  to="/shops/alphabet?letter=all"
                  className={isActive ? activeClassName : inactiveClassName}>
                Все магазины
            </Link>
            <div className="shopsCategoriesFilter__grid">
                {english.map(letter => (
                    <li key={letter}>
                        <AlphabetLink className="shopsCategoriesFilter__letter" letter={letter}>{letter}</AlphabetLink>
                    </li>
                ))}
            </div>
            <div className="shopsCategoriesFilter__grid">
                {russian.map(letter => (
                    <li key={letter}>
                        <AlphabetLink className="shopsCategoriesFilter__letter" letter={letter}>{letter}</AlphabetLink>
                    </li>
                ))}
            </div>
        </div>
    );
};

export default ShopsAlphabetFilter;
