import React from 'react';
import close from "../../../vendor/img/close.svg";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

type TRegisterSuccessPopup = {
    isOpened: boolean
    onClose: () => void
    setLoginPopupOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const RegisterSuccessPopup: React.FC<TRegisterSuccessPopup> = ({isOpened, onClose, setLoginPopupOpen}) => {
    const userInfo = useSelector((state: RootState) => state.authSlice.userInfo)

    const submitHandler = () => {
        onClose()
        setLoginPopupOpen(true)
    }

    return (
        <div className={isOpened ? "genericPopup genericPopup_visible" : "genericPopup"}>
            <div className="genericPopup__content">
                <img alt="крест" onClick={onClose} src={close} className="genericPopup__close"/>
                <h3 className="genericPopup__title">Вы активировали карту Клуба друзей.</h3>
                <p className="genericPopup__description">Вам доступна скидка {userInfo?.discountLevel}% в магазинах-партнерах Центра Галереи
                    Чижова. Все подробности о вашей карте можно узнать вличном кабинете.</p>
                <button onClick={submitHandler} type="button" className="genericPopup__button">Перейти в личный кабинет</button>
            </div>

        </div>
    );
};

export default RegisterSuccessPopup;