import './LandingSpring.scss'
import React from 'react'

const QABlock: React.FC = () => {

  const openAccordion = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    const element = e.currentTarget.nextElementSibling as HTMLElement;
    const contentElements = document.querySelectorAll('.QAContent') as NodeListOf<HTMLElement>;
    
    if (element?.style.maxHeight && element?.style.maxHeight !== '0px') {
      element.style.maxHeight = '0px';
      element.parentElement?.classList.remove('open')
    }
    else {
      contentElements.forEach(el=>{
        el.style.maxHeight = '0px';
        el.parentElement?.classList.remove('open')
      })
      element.style.maxHeight = `${element?.scrollHeight}px`;
      element.parentElement?.classList.add('open')
    }
  }

  return <ul className="QA-wrapper">
    <li className="QAItem">
      <h3 onClick={openAccordion}>1. Какие преимущества дает карта «Клуба друзей»? <i>&#x2716;</i></h3>
      <div className="QAContent">
        <p>Абсолютно все участники Розыгрыша получат виртуальную карту «Клуба друзей»</p>
        <br />
        <p>«Клуб друзей» - Программа лояльности Ассоциации «Галерея Чижова».</p>
        <br />
        <ul>
          <li><strong>1 этаж:</strong>+IT, Emporio Armani, Escada Sport, BOSS, Liu Jo, Marc O'Polo, Trussardi, Важный Аксессуар, Золотые Россыпи, Клевер;</li>
          <li><strong>2 этаж:</strong>Benetton, Meters/Bonwe, Мужской вкус;</li>
          <li><strong>3 этаж:</strong>Baleno, Gallery Shoes, Lescon, Luhta Finland Fashion, New Balance, ОАЗИС, Okaidi, Gallery Home, Kare, Gallery of Brands, Original Marines;</li>
          <li><strong>4 этаж:</strong>Chicco, Fashion Week Outlet.</li>
        </ul>
        <p>Владельцам карт «Клуба друзей» доступны:</p>
        <ul>
          <li>персональная скидка 15% в честь Дня рождения (за 3 дня до и 3 дня после Дня рождения);</li>
          <li>VIP-распродажи от более 40 ведущих ритейлеров;</li>
          <li>скидка по карте, которая растет с каждой новой покупкой.</li>
        </ul>
      </div>
    </li>
    <li className="QAItem">
      <h3 onClick={openAccordion}>2. Как понять, что я стал участником Розыгрыша? <i>&#x2716;</i></h3>
      <div className="QAContent">
        <p>Как только Вы загрузите чек(и) о покупке(ах) на сумму больше 2000 рублей в магазинах-
          участниках* Центра Галереи Чижова, он(и) попадет(ут) на модерацию. Для прохождения
          модерации также необходимо, чтобы сумма каждого чека была больше 500 рублей, и чеки
          содержали одну дату покупки.</p>
        <br />
        <p>После модерации, в качестве подтверждения регистрации чека(ов), Вам поступит СМС-
          сообщение на номер, указанный в анкете. В SMS будет написано количество купонов, которые делают Вас участником розыгрыша. Чем больше купонов у Вас будет, тем
          больше шансов на получение ПРИЗОВ.</p>
        <p className="small-italic-text">*не принимаются чеки из О`Кей, World Class, банкоматов, временных выставок</p>
      </div>
    </li>
    <li className="QAItem">
      <h3 onClick={openAccordion}>3. Как рассчитываются купоны для участия? <i>&#x2716;</i></h3>
      <div className="QAContent">
        <p>1 купон = 2000 рублей. Электронный купон участника Розыгрыша присваивается Вам по
          результату загрузки чеков, при условии, что Вы отсканировали чеки за покупки на 2000
          рублей и более (сумма каждого чека должна составлять больше 500 рублей, и покупки
          должны быть совершены в один день) за один день.</p>
        <br />
        <p>Например, Вы можете совершить одну покупку на 80000 рублей и получить 40 купонов. А
          можете каждый день покупать на 2000 рублей - и в течение месяца также получить 40
          купонов. Чем больше сумма чеков, тем выше Ваши шансы на получение ПРИЗОВ.</p>
        <br />
        <p>Более подробные условия вы можете изучить в <a href="https://docs.google.com/document/d/1mGt1ZpHS0aUW4OQgNw4NiyWs6pDQzJZW/edit?usp=sharing&ouid=102758596921326463577&rtpof=true&sd=true" target="_blanc">Правилах розыгрыша</a>.</p>
      </div>
    </li>
  </ul>
}

export default QABlock;