import React from 'react';
import SwiperMain from "../SwiperMain/SwiperMain";
import './MainSection.scss';
import {Link} from "react-router-dom";
import {calculatePadej} from "../../utils/calculatePadejs";
import {useGetShopsQuery} from "../../redux/shopsApi";
import CountUp from "react-countup";

const MainSection = () => {

    const {data: response = {shops: [], kidsShops: [], categories: []}, isLoading} = useGetShopsQuery()
    const shopsLength: number = response?.shops?.length
    const restaurantLength: number = response?.shops?.filter(shop => shop.categories[0]?.name === 'Кафе и рестораны').length

    return (
        <div className="mainSection">
            <SwiperMain />
            <div className="mainSection__grid">
                <Link to="/shops" className="mainSection__item mainSection__shops">{isLoading ? "Магазины" : <><CountUp className="mainSection__number" end={shopsLength} />{calculatePadej("магазин", shopsLength)}</> }</Link>
                <Link to="/shops?categorie=Кафе%20и%20рестораны" className="mainSection__item mainSection__restaurants">{isLoading ? "Рестораны" : <><CountUp className="mainSection__number" end={restaurantLength}/>{calculatePadej("ресторан", restaurantLength)}</>}</Link>
                <Link to="/shops?categorie=Развлечения" className="mainSection__item mainSection__entertainments">развлечения</Link>
                <Link to="/services" className="mainSection__item mainSection__uslugi">услуги</Link>
                <Link to="/promotions" className="mainSection__item mainSection__promotions">акции</Link>
                <Link to="/shops?categorie=Дети" className="mainSection__item mainSection__kids">дети</Link>
            </div>
        </div>
    );
};

export default MainSection;
