import React from 'react';
import './TgPopupPreview.scss';

type Props = {
    openPopup: () => void
}

const TgPopupPreview = ({openPopup}: Props) => {
    return (
        <div onClick={openPopup} className='tgPopupPreview'>
            Подписаться на наш телеграм
        </div>
    );
};

export default TgPopupPreview;