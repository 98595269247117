import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import './ShopsListsAlphabet.scss'
import ShopsListAlphabetBlock from "../ShopsListAlphabetBlock/ShopsListAlphabetBlock";
import Paginator from "../Paginator/Paginator";
import {createPages} from "../../utils/createPages";
import {useGetShopsQuery} from "../../redux/shopsApi";
import {TShop} from "../../redux/types";

const ShopsListAlphabet: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1)
    const totalPageCalc: number[] = []
    let [searchParams] = useSearchParams();
    let letterSearch = searchParams.get('letter');
    const pageSize = 15

    const { data: response } = useGetShopsQuery()

    const handlePageChange = (page: number): void => {
        setCurrentPage(page)
    }

    const shopsPerPage: TShop[] = [...response?.shops || []].splice((currentPage - 1) * pageSize, pageSize)

    const titleLettersPerPage: string[] = [...new Set(shopsPerPage.map(s => s.name[0].toUpperCase()))]
    const allLettersPerPage: string[] = [...new Set(response?.shops?.map(s => s.name[0].toUpperCase()))]
    const allShopsByLetter: TShop[] = response?.shops?.filter(s => s.name[0].toLowerCase() === letterSearch?.toLowerCase()) || []

    const filterTitleLettersPerPage = (letterArr: string): string[] => {
        return allLettersPerPage.filter(s => s.toLowerCase() === letterArr.toLowerCase())
    }

    const filterShopsByLetter = (letterArg: string): TShop[] | undefined => {
        return response?.shops.filter(s => s.name[0].toLowerCase() === letterArg.toLowerCase())
    }

    const shopsPerPageFiltered = (letter: string): TShop[] =>  [...filterShopsByLetter(letter) || []].splice((currentPage - 1) * pageSize, pageSize)

    const lettersForPage: string[] = React.useMemo(() => {
        if (!letterSearch) return titleLettersPerPage;
        if (letterSearch === 'all') return titleLettersPerPage;
        return filterTitleLettersPerPage(letterSearch);
    }, [titleLettersPerPage, letterSearch]);

    const totalShops: TShop[] = React.useMemo(() => {
        if (!letterSearch) return shopsPerPage;
        if (letterSearch === 'all') return shopsPerPage;
        return shopsPerPageFiltered(letterSearch)
    }, [letterSearch, shopsPerPage])

    const pagesCountA: number = Math.ceil((response?.shops.length || 1) / pageSize)
    const pagesCountB: number = Math.ceil(allShopsByLetter.length / pageSize)

    const pagesCount: number = React.useMemo(() => {
        if (!letterSearch) return pagesCountA;
        if (letterSearch === 'all') return pagesCountA;
        return pagesCountB
    }, [letterSearch, totalShops])

    createPages(totalPageCalc, pagesCount, currentPage)

    useEffect(() => {
        setCurrentPage(1)
    }, [letterSearch])

    return (
        <div className="shopListAlphabet">
            {lettersForPage.map((letter, i) => {
                return (
                    <ShopsListAlphabetBlock key={i} shopsPerPage={totalShops} letter={letter}  />
                );
            })}
            <Paginator pagesCount={pagesCount} currentPage={currentPage} handlePageChange={handlePageChange} totalPagesCalc={totalPageCalc} />
        </div>
    );
};

export default ShopsListAlphabet;
