import React from 'react';
import './Paginator.scss';

type TPaginator = {
    currentPage: number
    handlePageChange: (index: number) => void
    totalPagesCalc: number[]
    pagesCount?: number
}

const Paginator: React.FC<TPaginator> = ({handlePageChange, currentPage, totalPagesCalc, pagesCount}) => {

    return (
        <>
            {totalPagesCalc.length >=1 && <div className="pages-container">
            {currentPage !== 1 && <button onClick={() => handlePageChange( currentPage - 1 )} disabled={currentPage === 1} className="page">{'<<'}</button>}
            {totalPagesCalc.map((page, index) => <span className={currentPage === page ? "page page-active" : "page"}
                onClick={() => handlePageChange(page)}
                key={index}>{page}</span>)}
            <button disabled={currentPage === pagesCount} onClick={() => handlePageChange( currentPage + 1 )} className="page">{'>>'}</button>
                </div>}
        </>
    );
};

export default Paginator;