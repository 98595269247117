import React from 'react';
import './ChoosePopup.scss';
import close from '../../vendor/img/close.svg';
import viber from '../../vendor/img/viber-logo.svg';
import whatsapp from '../../vendor/img/whatsapp-logo.svg';
import telegram from '../../vendor/img/telegram-logo.svg';
import {Link} from "react-router-dom";

type TChoosePopup = {
    isOpened: boolean
    onClose: () => void
    openLoginPopup: () => void
}

const ChoosePopup: React.FC<TChoosePopup> = ({isOpened, onClose, openLoginPopup}) => {
    return (
        <div className={isOpened ? "choosePopup__layout choosePopup__layout_opened" : "choosePopup__layout"}>
            <div className="choosePopup__content">
                <img alt="крестик" src={close} onClick={onClose} className="choosePopup__close" />
                <Link to="/sign-up" onClick={() => onClose()} className="choosePopup__white-button">Активировать карту</Link>
                <button onClick={() => {onClose();openLoginPopup()}} className="choosePopup__black-button">Войти в личный кабинет</button>
                <p className="choosePopup__text">Служба поддержки клиентов<br />Клуба Друзей Ассоциации "Галерея Чижова"</p>
                <p className="choosePopup__phone">+7(473)261-99-99</p>
                <div className="choosePopup__icons-container">
                    <a className="choosePopup__link" href="https://wa.me/89100419999"><img className="choosePopup__logo" alt="ватсап" src={whatsapp} /></a>
                    <a className="choosePopup__link" href="https://viber.click/79100419999"><img className="choosePopup__logo" alt="вайбер" src={viber} /></a>
                    <a className="choosePopup__link" href="https://t.me/gallerychizhov"><img className="choosePopup__logo" alt="телеграм" src={telegram} /></a>
                </div>
                <p className="choosePopup__phone-smaller">+7 910 041 99 99, call-center@glch.ru</p>
                <p className="choosePopup__text-smaller">Время работы Службы поддержки:<br />с 9.00 до 21.00, без перерыва и выходных</p>
            </div>
        </div>
    );
};

export default ChoosePopup;
