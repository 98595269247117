import React from 'react';
import {Link} from "react-router-dom";
import {baseUrl} from "../../vendor/constants";
import servicePlaceholder from '../../vendor/img/service-placeholder.png'
import {TCategorie} from "../../redux/types";

type TUslugiCategory = {
    cat: TCategorie
}

const UslugiCategory: React.FC<TUslugiCategory> = ({cat}) => {

    const servicesCount = cat.services.length
    const categoryImage = cat.image ? baseUrl + cat.image.src : servicePlaceholder

    const renderCategories = () => {
        if (servicesCount === 1) {
            return (
                <Link to={`/services/${cat.slug}/${cat.services[0]?.slug}`}>
                    <div className="uslugiGrid__image-container">
                        <img alt={cat.name} className="uslugiGrid__image" src={categoryImage}/>
                    </div>
                </Link>
            )
        } else if (servicesCount > 1) {
            return (
                <Link to={`/services/${cat.slug}`}>
                    <div className="uslugiGrid__image-container">
                        <img alt={cat.name} className="uslugiGrid__image" src={categoryImage}/>
                    </div>
                </Link>
            )
        } else {
            return (
                <div className="uslugiGrid__image-container">
                    <img alt={cat.name} className="uslugiGrid__image" src={categoryImage}/>
                </div>

            )
        }
    }

    return (
        <li key={cat.slug} className="uslugiGrid__element">
            {renderCategories()}
            <div className="uslugiGrid__description">{cat.name}</div>
        </li>
    );
}

export default UslugiCategory;
