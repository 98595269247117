import {emptySplitApi} from "./emptySplitApi";
import {TShop} from "./types";

type TGetShopsResponse = {
    shops: TShop[]
    kidsShops: TShop[]
    categories: string[]
}



export const shopsApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getShops: build.query<TGetShopsResponse, void>({
            query: () => 'cgch/shop',
            transformResponse: (response: TShop[]) => ({
                shops: response.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)),
                kidsShops: response.filter(shop => shop.type === 2),
                categories: [...new Set(response.flatMap(shop => shop.categories.map(el => el.name)))],
            })
        }),
        getSingleShop: build.query<TShop, string | undefined>({
            query: (shopUrl) => `cgch/shop/view/${shopUrl}`
        })
    }),
    overrideExisting: false,
})

export const { useGetShopsQuery, useGetSingleShopQuery } = shopsApi