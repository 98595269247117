import React from 'react';
import {Link} from "react-router-dom";
import './NotFoundPage.scss';

const NotFoundPage:React.FC = () => {
    return (
        <div className="notfound">
            <h2 className="notfound__title">Страница не найдена</h2>
            <Link className="notfound__link" to="/">На главную</Link>
        </div>
    );
};

export default NotFoundPage;