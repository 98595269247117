import React from 'react';
import {baseUrl, mobileResolution, pcResolution} from "../../vendor/constants";

type Props = {
    images: {
        image: {
            src: string
            src_m: string
        }
    }[]
}

const InfoblockImages: React.FC<Props> = ({images}) => {

    return (
        <div className="infoblockImages__container">
            <div className="infoblockImages__main-image-container">
                <img srcSet={`${baseUrl + images[0].image?.src_m} ${mobileResolution}, ${baseUrl + images[0].image?.src} ${pcResolution}`} className="infoblockImages__image" src={baseUrl + images[0].image?.src} />
            </div>
            <div className="infoblockImages__images-container">
                <div className="infoblockImages__secondary-image-container">
                    <img srcSet={`${baseUrl + images[1].image?.src_m} ${mobileResolution}, ${baseUrl + images[1].image?.src} ${pcResolution}`} className="infoblockImages__image" src={baseUrl + images[1].image?.src} />
                </div>
                <div className="infoblockImages__secondary-image-container">
                    <img srcSet={`${baseUrl + images[2].image?.src_m} ${mobileResolution}, ${baseUrl + images[2].image?.src} ${pcResolution}`} className="infoblockImages__image" src={baseUrl + images[2].image?.src} />
                </div>
            </div>
        </div>
    );
};

export default InfoblockImages;
