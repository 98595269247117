import React from 'react';
import './Info.scss';
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import Purchase from "./Purchase/Purchase";

const Info: React.FC = () => {
    const userInfo = useSelector((state: RootState) => state.authSlice.userInfo)

    return (
        <div className="info">
            <div className="info__information">
                <div className="info__information-line"><p className="info__key">Номер карты:</p><p
                    className="info__value">{userInfo?.cardNumber}</p></div>
                <div className="info__information-line"><p className="info__key">Владелец:</p><p
                    className="info__value">{userInfo?.lastname} {userInfo?.firstname} {userInfo?.middlename} </p></div>
                <div className="info__information-line"><p className="info__key">Уровень скидки (%):</p><p
                    className="info__value">{userInfo?.discountLevel}</p></div>
                <div className="info__information-line"><p className="info__key">Ежемесячное накопление (₽):</p><p
                    className="info__value">{userInfo?.currentSavings}</p></div>
                <div className="info__information-line"><p className="info__key">Общая сумма накоплений (₽):</p><p
                    className="info__value">{userInfo?.totalSavings}</p></div>
            </div>
            <h3 className="info__title">Последние 10 покупок</h3>
            <div className="info__last-purchases">
                <div className="info__last-purchases-title">
                    <div>Дата и время</div>
                    <div>Магазин</div>
                    <div>Сумма</div>
                </div>
                {userInfo?.recentSales?.map((purchase, i) => {
                    return (
                        <Purchase key={i} purchase={purchase} />
                    )
                })}
            </div>
        </div>
    );
};

export default Info;