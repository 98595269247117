import React, {useState} from 'react';
import './Register.scss';
import {Link} from "react-router-dom";
import {FormikHelpers, useFormik} from "formik";
import {PersonSignUp, validationSignup} from "../../utils/validation";
import RegisterSuccessPopup from "./RegisterSuccessPopup/RegisterSuccessPopup";
import {useSignUpMutation} from "../../redux/authApi";

type TRegister = {
    setIsChoosePopupOpened: React.Dispatch<React.SetStateAction<boolean>>
}

const Register: React.FC<TRegister> = ({setIsChoosePopupOpened}) => {
    const [openRegisterSuccessPopup, setOpenRegisterSuccessPopup] = useState<boolean>(false)
    const [register, {isLoading}] = useSignUpMutation()

    const closePopup = () => {
        setOpenRegisterSuccessPopup(false)
    }


    const onSubmitForm = async (values: PersonSignUp, actions: FormikHelpers<PersonSignUp>) => {
        try {
            let formData = new FormData()
            let receive_call = +values.receive_call
            let send_sms = +values.send_sms
            let send_email = +values.send_email
            for (let value in values) {
                formData.append(value, String(values[value as keyof PersonSignUp]))
            }
            formData.delete('agreement')
            formData.set('receive_call', receive_call.toString())
            formData.set('send_sms', send_sms.toString())
            formData.set('send_email', send_email.toString())
            await register(formData).unwrap()
            setOpenRegisterSuccessPopup(true)
        } catch (e: any) {
            console.log(e.data.error)
            if (e.data.error === "user-exists" && e.data.errorMessage === "Пользователь с указанным номером телефона уже зарегистрирован в системе.") {
                actions.setStatus('Пользователь с таким номером телефона уже зарегистрирован')
            }
            else if (e.data.error === "user-exists" && e.data.errorMessage === "Пользователь с указанным почтовым адресом уже зарегистрирован в системе.") {
                actions.setStatus('Почтовый адрес занят другим пользователем')
            }
            else if (e.data.error === "card-activated") {
                actions.setStatus('Карта уже активирована')
            }
            else if (e.data.error === "card-notfound") {
                actions.setStatus('Указанной карты не существует')
            }
            else if (e.data.error === "card-used") {
                actions.setStatus('Карта используется другим пользователем')
            }
            else {
                actions.setStatus('Что-то пошло не так')
            }
        }
    }

    const formik = useFormik<PersonSignUp>({
        initialValues: {
            card: "",
            lastname: "",
            firstname: "",
            middlename: "",
            birthdate: "",
            email: "",
            phone: "",
            agreement: false,
            receive_call: true,
            send_sms: true,
            send_email: true
        },
        validationSchema: validationSignup,
        onSubmit: async (values, actions) => {
            await onSubmitForm(values, actions)
            actions.setSubmitting(false)
        }
    })

    return (
        <>
            <div className="bread-crumbs myProfile__bread-crumbs">
                <Link to="/" className="bread-crumbs-link">Главная</Link>
                <Link to="/sign-up" className="bread-crumbs-link">Активация карты</Link>
            </div>
            <form onSubmit={formik.handleSubmit} className="register">
                <h2 className="register__title">Добро пожаловать в Клуб друзей Ассоциации «Галерея Чижова»!</h2>
                <p className="register__subtitle">Участие в Программе лояльности «Клуб друзей» Ассоциации «Галерея Чижова» –
                    это повышенное качество обслуживания, возможность участия в розыгрышах призов, акциях и специальных мероприятиях, а также своевременное и актуальное информирование по телефону или другим видам связи.</p>
                <p className="register__description">Пожалуйста, заполните форму для активации Вашей карты. Верные
                    данные позволят своевременно
                    информировать
                    Вас об акциях, мероприятиях, скидках и распродажах нашего Центра.</p>
                <div className="register__inputs">
                    <label className="register__label">Номер карты клуба друзей:
                        <input id="card"
                               name="card"
                               type="text"
                               value={formik.values.card}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               placeholder="000000"
                               className="register__input" /></label>
                    {formik.touched.card && formik.errors.card &&
                      <div className="register__error">{formik.errors.card}</div>}
                    <label className="register__label">Фамилия:
                        <input id="lastname"
                               name="lastname"
                               type="text"
                               value={formik.values.lastname}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               placeholder="Иванов"
                               className="register__input" /></label>
                    {formik.touched.lastname && formik.errors.lastname &&
                      <div className="register__error">{formik.errors.lastname}</div>}
                    <label className="register__label">Имя:
                        <input placeholder="Иван"
                               id="firstname"
                               name="firstname"
                               type="text"
                               value={formik.values.firstname}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               className="register__input" /></label>
                    {formik.touched.firstname && formik.errors.firstname &&
                      <div className="register__error">{formik.errors.firstname}</div>}
                    <label className="register__label">Отчество:
                        <input placeholder="Иванович"
                               id="middlename"
                               name="middlename"
                               type="text"
                               value={formik.values.middlename}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               className="register__input" /></label>
                    {formik.touched.middlename && formik.errors.middlename &&
                      <div className="register__error">{formik.errors.middlename}</div>}
                    <label className="register__label">Дата рождения:
                        <input type="date"
                               id="birthdate"
                               name="birthdate"
                               value={formik.values.birthdate}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               placeholder="дд.мм.гггг"
                               className="register__input" /></label>
                    {formik.touched.birthdate && formik.errors.birthdate &&
                      <div className="register__error">{formik.errors.birthdate}</div>}
                    <label className="register__label">E-mail:
                        <input placeholder="email@mail.ru"
                               type="text"
                               id="email"
                               name="email"
                               value={formik.values.email}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               className="register__input" /></label>
                    {formik.touched.email && formik.errors.email &&
                      <div className="register__error">{formik.errors.email}</div>}
                    <label className="register__label">Телефон:
                        <input placeholder="89123456789"
                               type="text"
                               id="phone"
                               name="phone"
                               value={formik.values.phone}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               className="register__input" /></label>
                    {formik.touched.phone && formik.errors.phone &&
                      <div className="register__error">{formik.errors.phone}</div>}
                </div>
                <label className="register__label-checkbox">
                    <input className="register__input-checkbox"
                           id="agreement"
                           name="agreement"
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           type="checkbox"/>Я даю согласие на обработку своих персональных данных и
                    получение информационных сообщений
                    {formik.touched.agreement && formik.errors.agreement &&
                      <div className="register__error">{formik.errors.agreement}</div>}
                </label>
                <p className="register__checkboxes-title">Хочу получать:</p>
                <div className="register__checkboxes-container">
                    <label className="register__label-checkbox">
                        <input className="register__input-checkbox"
                               id="receive_call"
                               name="receive_call"
                               checked={formik.values.receive_call}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               type="checkbox"/>Звонок</label>
                    <label className="register__label-checkbox">
                        <input className="register__input-checkbox"
                               id="send_sms"
                               name="send_sms"
                               checked={formik.values.send_sms}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               type="checkbox"/>СМС-рассылку</label>
                    <label className="register__label-checkbox">
                        <input className="register__input-checkbox"
                               id="send_email"
                               name="send_email"
                               checked={formik.values.send_email}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               type="checkbox"/>Email-рассылку</label>
                </div>
                {!!formik.status && <div className="loginForm__error">{formik.status}</div>}
                <div className="register__buttons-container">
                    <button disabled={formik.isSubmitting} type="submit" className="register__submit">{isLoading ? 'Регистрация...' : 'Подать заявку'}</button>
                    <a href="https://glch.ru/page/13/polozhenie.html" className="register__instruction">Положение программы лояльности</a>
                </div>
            </form>
            <RegisterSuccessPopup setLoginPopupOpen={setIsChoosePopupOpened} onClose={closePopup} isOpened={openRegisterSuccessPopup} />
        </>
    );
};

export default Register;