import React from 'react';
import {TInfoblock} from "../../redux/types";
import SwiperInfoblock from "./SwiperInfoblock";

type Props = {
    block: TInfoblock
    index: number
}

const InfoblockText: React.FC<Props> = ({block, index}) => {
    const isOdd = index % 2 !== 0

    return (
        <div className={isOdd ? "infoblock  infoblock_reversed" : "infoblock"}>
            <SwiperInfoblock images={block?.images} />

            <div className="infoblock__text-container">
                <p className="infoblock__title">{block?.title}</p>
                {block?.text && <p className="infoblock__description" dangerouslySetInnerHTML={{__html: block?.text}}></p>}

            </div>

        </div>
    );
};

export default InfoblockText;
