import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import './ChoosenPromo.scss';
import vkLogo from '../../vendor/img/vk.svg';
import OtherPromosSwiper from '../OtherPromosSwiper/OtherPromosSwiper';
import {baseUrl, mobileResolution, pcResolution} from '../../vendor/constants';
import Preloader from "../Preloader/Preloader";
import {useGetPromotionsQuery, useGetSinglePromotionQuery} from "../../redux/promotionsApi";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import {TPromotion} from "../../redux/types";
import SwiperChoosenPromo from "../SwiperChoosenPromo/SwiperChoosenPromo";

const ChoosenPromo: React.FC = () => {
    const {promoLink} = useParams();
    const [sharePopup, setSharePopup] = useState<boolean>(false)
    const myShare = useRef<HTMLDivElement>(null)
    const {data: promotion, isFetching, isError} = useGetSinglePromotionQuery(promoLink)
    const {otherPromotions} = useGetPromotionsQuery(undefined, {
        selectFromResult: ({data}) => ({
            otherPromotions: data?.filter((promos) => promos.slug !== promotion?.slug)
        })
    })

    useEffect(() => {
        if (myShare.current !== null) {
            // @ts-ignore
            Ya.share2(myShare.current, {
                content: {
                    title: `${promotion?.name}`,
                    description: `${promotion?.description}`,
                    image: `${baseUrl + promotion?.image}`
                },
                theme: {
                    services: 'viber,telegram,vkontakte,whatsapp',
                    lang: 'ru',
                    colorScheme: 'whiteblack',
                    bare: false,
                    direction: 'horizontal'
                }
            })
        }
    }, [myShare.current])


    const startDate = (promotion: TPromotion | undefined): string | undefined => {
        if (promotion && promotion.start_at.length > 1) {
            return (
                new Date(promotion.start_at).toLocaleString('ru', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                })
            )
        }
    }

    const endDate = (promotion: TPromotion | undefined): string | undefined => {
        if (promotion && promotion.end_at.length > 1) {
            return (
                new Date(promotion.end_at).toLocaleString('ru', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                })
            )
        }
    }

    if (isFetching) return <Preloader/>

    if (isError) return <NotFoundPage/>

    return (
        <div className="choosenPromo">
            <div className="bread-crumbs">
                <Link to="/" className="bread-crumbs-link">Главная</Link>
                <Link to="/promotions" className="bread-crumbs-link">Все акции</Link>
                <Link to={`/promotions/${promotion?.slug}`} className="bread-crumbs-link">Акция</Link>
            </div>
            <div className="choosenPromo__block">
                <div className="choosenPromo__image-block">
                    {(promotion?.gallery && promotion.gallery.length > 0) ?
                        <SwiperChoosenPromo images={promotion.gallery}/> :
                        <div className="choosenPromo__image-container">
                            <img srcSet={`${baseUrl + promotion?.image.src_m} ${mobileResolution}, ${baseUrl + promotion?.image.src} ${pcResolution}`} alt="акция" className="choosenPromo__image" src={baseUrl + promotion?.image.src}/>
                        </div>}
                    {promotion?.shop &&
                      <div className="choosenPromo__share-buttons-block">
                        <p onClick={() => setSharePopup(!sharePopup)}
                           className="choosenPromo__share-button">Поделиться</p>
                        <div className={sharePopup ? "share-popup share-popup_opened" : "share-popup"} ref={myShare}/>
                        <a href={promotion.shop.link_vk}><img className="choosenPromo__social-logo" src={vkLogo}
                                                              alt="логотип Вконтакте"/></a>
                      </div>}
                </div>
                <div className="choosenPromo__text-block">
                    <div className="choosenPromo__title">{promotion?.name}</div>
                    {(promotion?.start_at && promotion?.end_at) && <div className="choosenPromo__time">С {startDate(promotion)} до {endDate(promotion)} </div>}
                    {promotion?.description && <div className="choosenPromo__description"
                                                    dangerouslySetInnerHTML={{__html: promotion.description}}/>}
                </div>
            </div>
            <div className="choosenPromo__other-promos-block">
                <h2 className="choosenPromo__other-promos-text">Другие новости и акции</h2>
                <OtherPromosSwiper promotions={otherPromotions}/>
            </div>
        </div>
    );
};


export default ChoosenPromo;
