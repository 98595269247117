import React, {useRef} from 'react';
import './SwiperEvents.scss'
import {Swiper, SwiperSlide, useSwiperSlide} from 'swiper/react';
import {baseUrl, mobileResolution, pcResolution} from '../../vendor/constants';
import {Link} from 'react-router-dom';
import {useGetEventsQuery} from "../../redux/eventsApi";
import imgFallback from "../../vendor/img/promo-fallback.png";
import {Swiper as SwiperCore} from 'swiper/types';


const SwiperEvents: React.FC = () => {
    const swiperRef = useRef<SwiperCore>();
    const {data: events} = useGetEventsQuery()

    return (
        <Swiper
            onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
            }}
            slidesPerView={'auto'}
            spaceBetween={20}
            className="swiperEvents"
        >
            {events?.map(slide => {
                const image = slide.image?.thumb ? baseUrl + slide.image.thumb : imgFallback
                return (
                    <SwiperSlide
                        className="swiperEvents__slide"
                        key={slide.slug}>
                        <div className="swiperEvents__img-container">
                            <img srcSet={`${baseUrl + slide.image.src_m} ${mobileResolution}, ${image} ${pcResolution}`} alt="логотип акции" className="swiperPromos__img" src={image}/>
                        </div>
                        <h3 className="swiperEvents__title">{slide.name}</h3>
                        <p className="swiperEvents__description">{slide.description_short}</p>
                        <Link className="swiperEvents__link" to={`events/${slide.slug}`}>Подробнее</Link>
                    </SwiperSlide>
                )
            })}
            <button className="swiperEvents__navigation swiperEvents__prev"
                    onClick={() => swiperRef.current?.slidePrev()}>
            </button>
            <button className="swiperEvents__navigation swiperEvents__next"
                    onClick={() => swiperRef.current?.slideNext()}>
            </button>
        </Swiper>


        // <Swiper slidesPerView={"auto"} spaceBetween={20} navigation={true} modules={[Navigation]}
        //         className="swiperEvents">
        //     {events?.map(slide => {
        //         return <SwiperSlide className="swiper__slide" key={slide.slug}>
        //             <div className="swiper__img-container">
        //                 <img alt="логотип события" className="swiper__img" src={baseUrl + slide.image?.thumb}/>
        //             </div>
        //             <h3 className="swiper__title">{slide.name}</h3>
        //             <p className="swiper__description">{slide.description_short}</p>
        //             <Link className="swiper__link" to={`events/${slide.slug}`}>Подробнее</Link>
        //         </SwiperSlide>
        //     })}
        // </Swiper>
    );
};

export default SwiperEvents;
