import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import './BurgerMenu.scss';
import clsx from "clsx";

const BurgerMenu: React.FC = () => {

    const {pathname} = useLocation()
    const isPageShop = pathname.startsWith('/shop/')

  return (
    <div className={clsx("burgerMenu", {
        "burgerMenu_shop" : isPageShop
    })}>
        <Link className="burgerMenu__link" to="/shops">Магазины</Link>
        <a href="https://glch.ru/" className="burgerMenu__link">Интернет-магазин</a>
        <Link className="burgerMenu__link" to="/loyalty">Программа лояльности</Link>
        <Link className="burgerMenu__link" to="/shops?categorie=Дети">Дети</Link>
        <a className="burgerMenu__link" href="https://residence-vrn.ru/">Резиденции Галереи Чижова</a>
        <a className="burgerMenu__link" href="https://worldclass-vrn.ru">World Class</a>
        <a className="burgerMenu__link" href="https://balagan-city.ru">Балаган Сити</a>
        <a className="burgerMenu__link" href="https://media.gallery-chizhov.ru/">Реклама в ЦГЧ</a>
        <a className="burgerMenu__link" href="https://map.gallery-chizhov.ru/">Карта</a>
        <Link className="burgerMenu__link" to="/services">Услуги</Link>
    </div>
  );
};

export default BurgerMenu;
