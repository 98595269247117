import React, {useRef} from 'react';
import './OtherPromosSwiper.scss';
import {Swiper, SwiperSlide} from 'swiper/react';


import {Link} from 'react-router-dom';
import {baseUrl, mobileResolution, pcResolution} from '../../vendor/constants';
import {TPromotion} from "../../redux/types";
import {Swiper as SwiperCore} from 'swiper/types';

type TOtherPromosSwiper = {
    promotions: TPromotion[] | undefined
}

const OtherPromosSwiper: React.FC<TOtherPromosSwiper> = ({ promotions }) => {
    const swiperRef = useRef<SwiperCore>();

  return (
    <Swiper
        onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
        }}
      className="otherPromosSwiper"
      spaceBetween={20}
      slidesPerView={"auto"}
    >
      {promotions?.map((slide, index) => {
        return (
          <SwiperSlide
            key={index}
            className="otherPromosSwiper__slide"
          >
            <div className="otherPromosSwiper__img-container">
              <img srcSet={`${baseUrl + slide?.image.src_m} ${mobileResolution}, ${baseUrl + slide?.image.src} ${pcResolution}`} className="otherPromosSwiper__img" src={baseUrl + slide.image?.thumb} />
            </div>
            <div className="otherPromosSwiper__title">{slide.name}</div>
            <div className="otherPromosSwiper__description">{slide.description_short}</div>
            <Link to={`/promotions/${slide.slug}`} className="otherPromosSwiper__button">Подробнее</Link>
          </SwiperSlide>
        )
      })}

        <button className="otherPromosSwiper__navigation otherPromosSwiper__prev"
                onClick={() => swiperRef.current?.slidePrev()}>
        </button>
        <button className="otherPromosSwiper__navigation otherPromosSwiper__next"
                onClick={() => swiperRef.current?.slideNext()}>
        </button>

    </Swiper>
  );
};

export default OtherPromosSwiper;
