import {emptySplitApi} from "./emptySplitApi";
import {TPhotoGallery} from "./types";

type TGetPhotoGalleries = {
    galleries: TPhotoGallery[]
    totalPagesCount: number
}

export const photoGalleryApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getPhotoGalleries: build.query<TGetPhotoGalleries, { page: number, year: number | null}>({
            query: ({page = 1, year}) => ({
                url: `cgch/gallery`,
                params: { page, year }
            }),
            transformResponse: (response: TPhotoGallery[], meta: any) => ({
                galleries: response,
                totalPagesCount: Number(meta?.response?.headers.get('x-pagination-page-count'))
            })
        }),
        getPhotoGallery: build.query<TPhotoGallery, string | undefined>({
            query: (link) => `cgch/gallery/view/${link}`
        })
    }),
    overrideExisting: false,
})

export const {useGetPhotoGalleriesQuery, useGetPhotoGalleryQuery} = photoGalleryApi