import React from 'react';
import {Link, LinkProps, useSearchParams} from 'react-router-dom';

interface IAlphabetLink extends Omit<LinkProps, "to"> {
    letter: string
}

const AlphabetLink: React.FC<IAlphabetLink> = ({ letter, children, ...props }) => {
  let [searchParams] = useSearchParams();
  let isActive = searchParams.get("letter") === letter

  return (
    <Link
    to={`/shops/alphabet?letter=${letter}`}
    className=""
    {...props}
      style={{
        ...props.style,
        color: isActive ? "#000000" : "rgb(0 0 0 / 55%)"
      }}
    >
      {children}
    </Link>
  );
};

export default AlphabetLink;
