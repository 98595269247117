import React from 'react';
import './Questions.scss';
import Question from "./Question";
import {useGetQAQuery} from "../../../redux/otherApi";
import Preloader from "../../Preloader/Preloader";
import NotFoundPage from "../../NotFoundPage/NotFoundPage";

const Questions = () => {
    const {data: questions, isLoading, isError} = useGetQAQuery()

    if (isLoading) return <Preloader />

    if (isError) return <NotFoundPage />

    return (
        <div className="questions">
            {questions?.map((question) => {
                return (
                    <Question key={question.question} question={question} />
                )
            })}

        </div>
    );
};

export default Questions;