import React, {useRef} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import './ChoosenShop.scss';
import {Swiper as SwiperCore} from 'swiper/types';
import {baseUrl, mobileResolution, pcResolution} from "../../vendor/constants";
import {Pagination} from "swiper";

type Props = {
    images: {
        image: {
            src: string
            src_m: string
        }
    }[]
}

const SwiperInfoblock: React.FC<Props> = ({images}) => {
    const swiperRef = useRef<SwiperCore>();

    return (
        <div className="swiperInfoblock">
            <Swiper
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                }}
                slidesPerView={1}
                className="swiperInfoblock__swiper"
                pagination={{
                    clickable: true,
                    bulletClass: 'swiperInfoblock__pagination-bullet',
                    bulletActiveClass: 'swiperInfoblock__pagination-bullet-active',
                    horizontalClass: 'swiperInfoblock__pagination-container',
                }}
                modules={[Pagination]}

            >
                {images?.map(image => {

                    return (
                        <SwiperSlide className="swiperInfoblock__slide" key={image.image?.src}>
                            <img
                                srcSet={`${baseUrl + image.image?.src_m} ${mobileResolution}, ${baseUrl + image.image?.src} ${pcResolution}`}
                                alt="" className="swiperInfoblock__img" src={baseUrl + image.image?.src}/>
                        </SwiperSlide>
                    )
                })}

            </Swiper>
            {images.length > 1 &&
              <>
                <button className="swiperInfoblock__navigation swiperInfoblock__prev"
                        onClick={() => swiperRef.current?.slidePrev()}>
                </button>
                <button className="swiperInfoblock__navigation swiperInfoblock__next"
                        onClick={() => swiperRef.current?.slideNext()}>
                </button>
              </>
            }
        </div>
    );
};

export default SwiperInfoblock;
