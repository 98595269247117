import React from 'react';
import './EventsCard.scss';
import {baseUrl, mobileResolution, pcResolution} from "../../vendor/constants";
import { Link } from "react-router-dom";
import { TEvent } from "../../redux/types";

type TEventsCard = {
    event: TEvent
}

const EventsCard: React.FC<TEventsCard> = ({ event }) => {

    return (
        <div className="promotionsGrid__card">
            <img srcSet={`${baseUrl + event.image.src_m} ${mobileResolution}, ${baseUrl + event.image.src} ${pcResolution}`} className="promotionsGrid__card-image" alt={event.name} src={baseUrl + event.image.thumb} />
            {event?.start_at ? <p className="promotionsGrid__card-time">C {event.start_at}</p> : null}
            <h3 className="promotionsGrid__card-title">{event.name}</h3>
            <Link to={`/events/${event.slug}`} className="promotionsGrid__button">Подробнее</Link>
        </div>
    );
};

export default EventsCard;
