import React, {useRef, useState} from 'react';
import './SwiperChoosenGallery.scss'

import {Swiper, SwiperSlide} from "swiper/react";
import {Thumbs} from "swiper";
import {baseUrl} from "../../vendor/constants";
import {TImage} from "../../redux/types";
import {Swiper as SwiperCore} from 'swiper/types';

type TChoosenGallerySwiper = {
    photos: TPhoto[] | undefined
}

type TPhoto = {
    image: TImage
    main: number
}

const SwiperChoosenGallery: React.FC<TChoosenGallerySwiper> = ({photos}) => {
    const swiperRef = useRef<SwiperCore>();
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

    return (
        <div className="swiperChoosenGallery">
            <Swiper
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                }}
                spaceBetween={10}
                slidesPerView={1}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[Thumbs]}
                className="swiperChoosenGallery__mainSwiper"
            >
                {photos?.map((photo, index) => {
                    return (
                        <SwiperSlide className="swiperChoosenGallery__mainSwiperSlide" key={index}>
                            <img className="swiperChoosenGallery__img" src={baseUrl + photo.image.src} />
                        </SwiperSlide>
                    )
                })}
                <button className="swiperChoosenGallery__navigation swiperChoosenGallery__prev"
                        onClick={() => swiperRef.current?.slidePrev()}>
                </button>
                <button className="swiperChoosenGallery__navigation swiperChoosenGallery__next"
                        onClick={() => swiperRef.current?.slideNext()}>
                </button>
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={20}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[Thumbs]}
                className="swiperChoosenGallery__miniSwiper"
            >
                {photos?.map((photo, index) => {
                    return (
                        <SwiperSlide className="swiperChoosenGallery__miniSwiperSlide" key={index}>
                            <img className="swiperChoosenGallery__img" src={baseUrl + photo.image.src} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    );
};

export default SwiperChoosenGallery;
