import React from 'react';
import './Promotions.scss';
import SwiperPromos from '../SwiperPromos/SwiperPromos';
import {Link} from "react-router-dom";

const Promotions: React.FC = () => {

    return (
        <div className="promotions">
            <div className="promotions__container">
                <h2 className="promotions__title">Новости и акции</h2>
                <Link className="promotions__link" to="promotions">Смотреть все</Link>
            </div>
            <SwiperPromos />
        </div>
    );
};


export default Promotions;

