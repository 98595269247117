import React from 'react';
import './Shopslist.scss';
import { useLocation } from 'react-router-dom';
import ShopsListCategories from '../ShopsListCategories/ShopsListCategories';
import ShopsListAlphabet from '../ShopsListAlphabet/ShopsListAlphabet';

const ShopsList: React.FC = () => {
  const location = useLocation();


  return (
    <>
      {location.pathname === '/shops' && <ShopsListCategories />}
      {location.pathname === '/shops/alphabet' && <ShopsListAlphabet />}
    </>
  );
};

export default ShopsList;
