import {configureStore} from '@reduxjs/toolkit';
import {useDispatch} from "react-redux";
import authSlice from "./authSlice";
import {emptySplitApi} from "./emptySplitApi";

export const store = configureStore({
    reducer: {
        authSlice,
        [emptySplitApi.reducerPath]: emptySplitApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(emptySplitApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()