import React, {useCallback, useState} from 'react';
import './ContactUs.scss';
import {useFormik} from "formik";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {FormContactUs, validationContactUs} from "../../../utils/validation";
import ContactUsSuccessPopup from "./ContactUsSuccessPopup/ContactUsSuccessPopup";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {useSendFeedbackMutation} from "../../../redux/authApi";
import Preloader from "../../Preloader/Preloader";

const ContactUs = () => {
    const RECAPTHCA_KEY = '6LeZ9a0iAAAAACXBH0nxG8um0N_2uLZyv4Fc-Xfc';
    const userInfo = useSelector((state: RootState) => state.authSlice.userInfo)
    const [openSuccessPopup, setOpenSuccessPopup] = useState<boolean>(false)
    const [captchaToken, setCaptchaToken] = useState<string>('');
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [sendFeedback, { isLoading }] = useSendFeedbackMutation()

    const onVerify = useCallback((token:string) => {
        setCaptchaToken(token);
      }, []);

    const closePopup = () => {
        setOpenSuccessPopup(false)
    }

    const sendFormHandler = async (values: FormContactUs, captcha: string) => {
        try {
            const formData = new FormData();
            for (let value in values) {
                formData.append(value, String(values[value as keyof FormContactUs]))
            }
            formData.set('reCaptcha', captcha)
            await sendFeedback(formData).unwrap()
            setOpenSuccessPopup(true)
        } catch (e) {
            console.log(e)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            lastname: userInfo?.lastname || '',
            firstname: userInfo?.firstname || '',
            middlename: userInfo?.middlename || '',
            phone: userInfo?.phone || '',
            email: userInfo?.email || '',
            text: ''
        },
        validationSchema: validationContactUs,
        onSubmit: async (values, {setSubmitting}) => {
            await sendFormHandler(values, captchaToken)
            setSubmitting(false)
            setRefreshReCaptcha(r=>!r)
        }
    })


    return (
        <GoogleReCaptchaProvider 
        reCaptchaKey={RECAPTHCA_KEY}
        >

            <form onSubmit={formik.handleSubmit} className="contactUs">
                <p className="contactUs__text">Если Вы не нашли ответ на Ваш вопрос, воспользуйтесь формой обратной
                    связи
                    или обратитесь в службу
                    поддержки клиентов «Клуба Ассоциации Галерея Чижова» по телефону +7(473)261-99-99</p>
                <label className="contactUs__label" htmlFor="lastname">Фамилия:</label>
                <input className="contactUs__input"
                       placeholder="Иванов"
                       type="text"
                       id="lastname"
                       name="lastname"
                       onChange={formik.handleChange}
                       value={formik.values.lastname}
                       onBlur={formik.handleBlur}
                />
                {formik.touched.lastname && formik.errors.lastname &&
                  <div className="loginForm__error">{formik.errors.lastname}</div>}
                <label className="contactUs__label" htmlFor="name">Имя:</label>
                <input className="contactUs__input"
                       placeholder="Иван"
                       type="text"
                       id="firstname"
                       name="firstname"
                       onChange={formik.handleChange}
                       value={formik.values.firstname}
                       onBlur={formik.handleBlur}
                />
                {formik.touched.firstname && formik.errors.firstname &&
                  <div className="loginForm__error">{formik.errors.firstname}</div>}
                <label className="contactUs__label" htmlFor="middlename">Отчество:</label>
                <input className="contactUs__input"
                       placeholder="Иванович"
                       type="text"
                       id="middlename"
                       name="middlename"
                       onChange={formik.handleChange}
                       value={formik.values.middlename}
                       onBlur={formik.handleBlur}
                />
                {formik.touched.lastname && formik.errors.lastname &&
                  <div className="loginForm__error">{formik.errors.middlename}</div>}
                <label className="contactUs__label" htmlFor="phone">Номер телефона:</label>
                <input className="contactUs__input"
                       placeholder="+7 910 288-37-77"
                       type="text"
                       id="phone"
                       name="phone"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone &&
                  <div className="loginForm__error">{formik.errors.phone}</div>}
                <label className="contactUs__label" htmlFor="email">E-mail:</label>
                <input className="contactUs__input"
                       placeholder="email@mail.ru"
                       type="text"
                       id="email"
                       name="email"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email &&
                  <div className="loginForm__error">{formik.errors.email}</div>}
                <label className="contactUs__label">Оставьте ваше сообщение:</label>
                <textarea className="contactUs__textarea"
                          placeholder="Причина"
                          id="text"
                          name="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.text}
                />
                {formik.touched.text && formik.errors.text &&
                  <div className="loginForm__error">{formik.errors.text}</div>}
                  <GoogleReCaptcha
                    action='homepage'
                    onVerify={onVerify}
                    refreshReCaptcha={refreshReCaptcha}
            />
                {isLoading ? <Preloader /> : <button disabled={formik.isSubmitting} type="submit" className="contactUs__button">Отправить</button>}
            </form>
            <ContactUsSuccessPopup isOpened={openSuccessPopup} onClose={closePopup}/>
        </GoogleReCaptchaProvider>
    );
};

export default ContactUs;