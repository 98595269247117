import React, {useState} from 'react';
import {Field, Form, Formik} from 'formik';
import "./SignEmail.scss";
import {useSubscribeMutation} from "../../redux/otherApi";

type MyFormikValues = {
    email: string
}

const SignEmailForm: React.FC = () => {
    const [feedbackMessageStatus, setFeedbackMessageStatus] = useState<number>(0)
    const [showFeedbackMessage, setShowFeedbackMessage] = useState<boolean>(false)
    const [subscribe, { isLoading }] = useSubscribeMutation()

    const handleSubmit = (email: string) => subscribe(email).unwrap()

    const initialValues: MyFormikValues = {
        email: '',
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    handleSubmit(values.email)
                        .then(() => {
                            setShowFeedbackMessage(true);
                            setFeedbackMessageStatus(1);
                        })
                        .catch(() => {
                            setShowFeedbackMessage(true);
                            setFeedbackMessageStatus(2)
                        })
                }}
            >
                <Form>
                    <div className="signEmail__main-container">
                        <Field className="signEmail__input" type="email" placeholder="E-mail" id="email"
                               name="email" required/>
                        <button name="button" className="signEmail__button" type="submit">Подписаться</button>
                    </div>
                    {showFeedbackMessage &&
                      <>
            <span
              className={feedbackMessageStatus === 1 ? 'signEmail__feedback-success signEmail__feedback-success_visible' : 'signEmail__feedback-success'}>Вы успешно подписались на рассылку!</span>
                        <span
                          className={feedbackMessageStatus === 2 ? 'signEmail__feedback-fail signEmail__feedback-success_visible' : 'signEmail__feedback-fail'}>Произошла ошибка при попытке подписаться на рассылку!</span>
                      </>
                    }
                </Form>
            </Formik>
        </div>
    );
};

export default SignEmailForm;