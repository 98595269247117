import React from 'react';
import './LandingPage.scss';
import {baseUrl, mobileResolution, pcResolution} from "../../vendor/constants";
import InfoblockText from "../ChoosenShop/InfoblockText";
import InfoblockImages from "../ChoosenShop/InfoblockImages";
import SwiperGallery from "../ChoosenShop/SwiperGallery";
import {Link, useParams} from "react-router-dom";
import {useGetLandingPageQuery} from "../../redux/otherApi";
import Preloader from "../Preloader/Preloader";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import {useInView} from "react-intersection-observer";

type Props = {
    windowWidth: number | null
}

const LandingPage = ({windowWidth}: Props) => {
    const {pageLink} = useParams()
    const {data: page, isFetching, isError} = useGetLandingPageQuery(pageLink)
    const hasGallery = page?.gallery !== undefined && page?.gallery?.length > 0
    const infoblocksText = page?.infoblocks?.filter((block) => block.type === 1)
    const infoblocksImages = page?.infoblocks?.filter((block) => block.type === 2)
    const hasInfoblocksText = infoblocksText !== undefined && infoblocksText.length > 0
    const hasInfoblocksImages = infoblocksImages !== undefined && infoblocksImages[0].images.length === 3
    const hasShops = page?.shops !== undefined && page?.shops.length > 0

    const {ref, inView, entry} = useInView({
        threshold: 0,
    });

    if (isFetching) return <Preloader/>

    if (isError) return <NotFoundPage/>

    return (
        <div className="landingPage">
            {page?.headImage !== null && <div className="landingPage__header">
              <picture>
                <source srcSet={`${baseUrl + page?.headImage.src_m}`} media={`(max-width: 500px)`} />
                <img className="landingPage__header-content" src={baseUrl + page?.headImage?.src} alt="" />
              </picture>
            </div>
            }
            <div className="landingPage__container">
                <div className="landingPage__shop-info-block">
                    <div
                        className={page?.headImage !== null ? "landingPage__logo-container landingPage__logo-container_moved" : "landingPage__logo-container"}>
                        <img
                            srcSet={`${baseUrl + page?.image?.src_m} ${mobileResolution}, ${baseUrl + page?.image?.src} ${pcResolution}`}
                            className="landingPage__logo" alt={page?.name}/>

                    </div>
                    <div className="landingPage__shop-info">
                        <h3 className="landingPage__title">{page?.name}</h3>
                        {page?.description && <p className="landingPage__description"
                                                 dangerouslySetInnerHTML={{__html: page?.description}}></p>}
                    </div>
                </div>
                {hasShops && <div className="landingPage__shops-block">
                  <h3 className="landingPage__subtitle">Участники</h3>
                  <div className="landingPage__shops-container">
                      {page.shops.map((shop) => {
                          return (
                              <Link key={shop.slug} to={`/shop/${shop.slug}`} className="landingPage__shop-container">
                                  {shop.has_collection && <span
                                    className="landingPage__collection landingPage__tag_black">новая коллекция</span>}
                                  {shop.limited_action_text ? <div className="landingPage__tags-block"><span
                                          className="landingPage__tag_white">{shop.limited_action_text}</span></div>
                                      :
                                      shop.discount_percent && <div className="landingPage__tags-block">
                                          {shop.has_collection && <span className="landingPage__tag_white">распродажа</span>}
                                      <span className="landingPage__tag_white">скидки до -{shop.discount_percent}%</span>
                                    </div>
                                  }
                                  <img className="landingPage__shop"
                                       srcSet={`${baseUrl + shop.logo?.src_m} ${mobileResolution}, ${baseUrl + shop?.logo?.src} ${pcResolution}`}/>
                              </Link>
                          )
                      })}
                  </div>
                </div>
                }
                {hasInfoblocksText &&
                  <div className="landingPage__blocks-container">
                      {infoblocksText.map((block, index) => {
                          return (
                              <InfoblockText key={block.title} index={index} block={block}/>
                          )
                      })}
                  </div>
                }
                {hasInfoblocksImages &&
                  <div className="infoblockImages">
                      {infoblocksImages.map((block) => {
                          return (
                              <InfoblockImages key={block.images[0].image?.src} images={block.images}/>
                          )
                      })}
                  </div>
                }
                {hasGallery &&
                  <div className="landingPage__gallery">
                    <SwiperGallery images={page?.gallery}/>
                  </div>
                }


                {page?.mapImage && <div className="landingPage__map">
                  <div className="landingPage__map-iframe-container">
                    && <img
                    srcSet={`${baseUrl + page.mapImage.src_m} ${mobileResolution}, ${baseUrl + page.mapImage.src} ${pcResolution}`}
                    className="landingPage__map-iframe" src={baseUrl + page.mapImage.src}/>
                  </div>
                </div>}
            </div>
            {(windowWidth && windowWidth > 768) && page?.headVideo &&
              <div ref={ref} className="landingPage__video-block">
                <video autoPlay muted loop
                       className={inView ? "landingPage__video landingPage__video_visible" : "landingPage__video"}
                       poster={page?.headImage ? baseUrl + page?.headImage.src : ""}
                       src={baseUrl + page?.headVideo}></video>
              </div>}
            {(windowWidth && windowWidth <= 768) && page?.headVideoMobile &&
              <div ref={ref} className="landingPage__video-block">
                <video autoPlay muted loop
                       className={inView ? "landingPage__video landingPage__video_visible" : "landingPage__video"}
                       poster={page?.headImage ? baseUrl + page?.headImage.src : ""}
                       src={baseUrl + page?.headVideoMobile}></video>
              </div>}
        </div>
    );
};

export default LandingPage;
