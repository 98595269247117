import React from 'react';
import close from "../../../../vendor/img/close.svg";

type TContactUsSuccessPopup = {
    isOpened: boolean
    onClose: () => void
}

const ContactUsSuccessPopup: React.FC<TContactUsSuccessPopup> = ({isOpened, onClose}) => {

    return (
        <div className={isOpened ? "genericPopup genericPopup_visible" : "genericPopup"}>
            <div className="genericPopup__content">
                <img onClick={onClose} alt="крест" src={close} className="genericPopup__close"/>
                <h3 className="genericPopup__title">Спасибо! Мы свяжемся с вами!</h3>
                <button onClick={onClose} type="button" className="genericPopup__button">Вернуться в личный кабинет</button>
            </div>
        </div>
    );
};

export default ContactUsSuccessPopup;