import React, {useState} from 'react';
import './ChangeInfo.scss';
import {FormikHelpers, useFormik} from "formik";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../redux/store";
import {validationUpdateUserInfo} from "../../../utils/validation";
import CancelSmsPopup from "./CancelSmsPopup/CancelSmsPopup";
import GenericPopup from "./GenericPopup/GenericPopup";
import SubscribeSmsPopup from "./SubscribeSmsPopup/SubscribeSmsPopup";
import ThanksPopup from "./ThanksPopup/ThanksPopup";
import CancelEmailPopup from "./CancelEmailPopup/CancelEmailPopup";
import {useGetUserInfoQuery, useUpdateUserInfoMutation} from "../../../redux/authApi";
import {skipToken} from "@reduxjs/toolkit/query";
import Preloader from "../../Preloader/Preloader";

export type TUpdateUserInfo = {
    lastname: string
    firstname: string
    middlename: string
    birthdate: string
    phone: string
    email: string
    address: string
    sex: number
    familyStatus: number
    kidsCount: string
    kidsNames: string
    sendSms: boolean
    sendSmsReasons: string[]
    sendSmsReasonText: string
    sendEmail: boolean
    sendEmailReasons: string[]
    sendEmailReasonText: string
}

const ChangeInfo: React.FC = () => {
    const userInfo = useSelector((state: RootState) => state.authSlice.userInfo)
    const [openSex, setOpenSex] = useState<boolean>(false)
    const [openFamilyStatus, setOpenFamilyStatus] = useState<boolean>(false)
    const sexList = ["мужской", "женский"]
    const familyStatusList = ["женат", "замужем", "в гражданском браке", "не женат", "не замужем", "в разводе", "вдовец", "вдова"]
    const [openCancelSmsPopup, setOpenCancelSmsPopup] = useState<boolean>(false)
    const [openCancelEmailPopup, setOpenCancelEmailPopup] = useState<boolean>(false)
    const [openGenericPopup, setOpenGenericPopup] = useState<boolean>(false)
    const [openSubscribeSmsPopup, setOpenSubscribeSmsPopup] = useState<boolean>(false)
    const [openThanksPopup, setOpenThanksPopup] = useState<boolean>(false)
    const [sendSmsReason, setSendSmsReason] = useState<boolean>(false)
    const [sendEmailReason, setSendEmailReason] = useState<boolean>(false)
    const [updateUserInfo, {isLoading}] = useUpdateUserInfoMutation()
    const closeGenericPopup = () => {
        setOpenGenericPopup(false)
        setOpenSubscribeSmsPopup(false)
        setOpenThanksPopup(false)
        setOpenCancelSmsPopup(false)
        setOpenCancelEmailPopup(false)
    }

    const handleUpdateSubmit = async (values: TUpdateUserInfo,
                                         actions: FormikHelpers<TUpdateUserInfo>,
                                         sendSmsReason: boolean,
                                         sendEmailReason: boolean) => {
        const formData = new FormData();
        let send_sms = +values.sendSms
        let send_email = +values.sendEmail
        let reason_sms = values.sendSmsReasons.join() + values.sendSmsReasonText
        let reason_email = values.sendEmailReasons.join() + values.sendEmailReasonText
        if (sendSmsReason) {
            formData.set('reason_sms', String(reason_sms))
            formData.set('send_sms', String(send_sms))
        }
        if (sendEmailReason) {
            formData.set('reason_email', String(reason_email))
            formData.set('send_email', String(send_email))
        }
        if (values.kidsCount) {
            formData.set('children_count', String(values.kidsCount))
        }
        for (let value in values) {
            formData.append(value, String(values[value as keyof TUpdateUserInfo]))
        }
        formData.delete('sex')
        formData.delete('familyStatus')
        formData.delete('kidsCount')
        formData.delete('kidsNames')
        formData.delete('sendSms')
        formData.delete('sendEmail')
        formData.delete('sendSmsReasons')
        formData.delete('sendSmsReasonText')
        formData.delete('sendEmailReasons')
        formData.delete('sendEmailReasonText')
        formData.set('gender', String(values.sex))
        formData.set('marital_status', String(values.familyStatus))
        formData.set('children_names', String(values.kidsNames))
        try {
            await updateUserInfo(formData).unwrap()
        } catch (e) {
            actions.setStatus('Ошибка. Обратитесь в службу поддержки')
        }
    }

    const validationSchemaUserUpdate = validationUpdateUserInfo(userInfo?.sendSms, userInfo?.sendEmail)

    const formik = useFormik<TUpdateUserInfo>({
        enableReinitialize: true,
        initialValues: {
            lastname: userInfo?.lastname || '',
            firstname: userInfo?.firstname || '',
            middlename: userInfo?.middlename || '',
            birthdate: userInfo?.birthdate || '',
            phone: userInfo?.phone || '',
            email: userInfo?.email || '',
            address: userInfo?.address || '',
            sex: userInfo?.gender || 0,
            familyStatus: userInfo?.maritalStatus || 0,
            kidsCount: userInfo?.childrenCount || '',
            kidsNames: userInfo?.childrenNames || '',
            sendSms: userInfo?.sendSms || false,
            sendEmail: userInfo?.sendEmail || false,
            sendSmsReasons: [] || '',
            sendSmsReasonText: '',
            sendEmailReasons: [] || '',
            sendEmailReasonText: ''
        },
        validationSchema: validationSchemaUserUpdate,

        onSubmit: async (values, actions) => {
            await handleUpdateSubmit(values, actions, sendSmsReason, sendEmailReason)
            actions.setSubmitting(false)
        }
    })

    const onChangeSmsHandler = () => {
        formik.setFieldValue('sendSms', (!formik.values.sendSms))
        setSendSmsReason(true)
        if (formik.values.sendSms == userInfo?.sendSms) {
            if (userInfo.sendSms) {
                setOpenCancelSmsPopup(true)
            } else {
                setOpenSubscribeSmsPopup(true)
            }
        }
    }

    const onChangeEmailHandler = () => {
        formik.setFieldValue('sendEmail', (!formik.values.sendEmail))
        setSendEmailReason(true)
        if (formik.values.sendEmail == userInfo?.sendEmail) {
            if (userInfo.sendEmail) {
                setOpenCancelEmailPopup(true)
            } else {
                setOpenSubscribeSmsPopup(true)
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit} className="changeInfo">
            <div className="changeInfo__row">
                <label className="changeInfo__key">Фамилия:</label>
                <input className="changeInfo__input"
                       id="lastname"
                       name="lastname"
                       type="text"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.lastname}/>
            </div>
            {formik.touched.lastname && formik.errors.lastname &&
              <div className="loginForm__error">{formik.errors.lastname}</div>}
            <div className="changeInfo__row">
                <label className="changeInfo__key">Имя:</label>
                <input className="changeInfo__input"
                       id="firstname"
                       name="firstname"
                       type="text"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.firstname}/>
            </div>
            {formik.touched.firstname && formik.errors.firstname &&
              <div className="loginForm__error">{formik.errors.firstname}</div>}
            <div className="changeInfo__row">
                <label className="changeInfo__key">Отчество:</label>
                <input className="changeInfo__input"
                       id="middlename"
                       name="middlename"
                       type="text"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.middlename}/>
            </div>
            {formik.touched.middlename && formik.errors.middlename &&
              <div className="loginForm__error">{formik.errors.middlename}</div>}
            <div className="changeInfo__row">
                <label className="changeInfo__key">Дата рождения:</label>
                <input className="changeInfo__input"
                       id="birthdate"
                       name="birthdate"
                       type="date"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.birthdate}/>
            </div>
            {formik.touched.birthdate && formik.errors.birthdate &&
              <div className="loginForm__error">{formik.errors.birthdate}</div>}
            <div onClick={() => setOpenSex(!openSex)} className="changeInfo__row">
                <label className="changeInfo__key">Пол:</label>
                <div className="changeInfo__input-div">{sexList[Number(formik.values.sex) - 1]}</div>
                <input className="changeInfo__input hidden"
                       id="sex"
                       name="sex"
                       type="text"
                       readOnly
                       value={formik.values.sex}
                       onChange={formik.handleChange}
                />
                {openSex &&
                  <div className="changeInfo__popup">
                    <ul>
                        {sexList.map((sex, i) => (
                            <li key={i}
                                onClick={() => formik.setFieldValue('sex', i + 1)}
                                className={formik.values.sex === i + 1 ? 'active' : ''}
                            >
                                {sex}
                            </li>
                        ))}
                    </ul>
                  </div>
                }
            </div>
            <div className="changeInfo__row"><
                label className="changeInfo__key">Номер телефона:</label>
                <input className="changeInfo__input"
                       id="phone"
                       name="phone"
                       type="text"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.phone}/>
            </div>
            {formik.touched.phone && formik.errors.phone &&
              <div className="loginForm__error">{formik.errors.phone}</div>}
            <div className="changeInfo__row">
                <label className="changeInfo__key">Email:</label>
                <input className="changeInfo__input"
                       id="email"
                       name="email"
                       type="text"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.email}/>
            </div>
            {formik.touched.email && formik.errors.email &&
              <div className="loginForm__error">{formik.errors.email}</div>}
            <div className="changeInfo__row">
                <label className="changeInfo__key">Адрес проживания:</label>
                <input className="changeInfo__input"
                       id="address"
                       name="address"
                       type="text"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.address}/>
            </div>
            <div onClick={() => setOpenFamilyStatus(!openFamilyStatus)} className="changeInfo__row">
                <label className="changeInfo__key">Семейное положение:</label>
                <div className="changeInfo__input-div">{familyStatusList[Number(formik.values.familyStatus) - 1]}</div>
                <input className="changeInfo__input hidden"
                       id="familyStatus"
                       name="familyStatus"
                       type="text"
                       readOnly
                       value={formik.values.familyStatus}
                       onChange={formik.handleChange}
                />
                {openFamilyStatus &&
                  <div className="changeInfo__popup">
                    <ul>
                        {familyStatusList.map((familyStatus, i) => (
                            <li key={i}
                                onClick={() => formik.setFieldValue('familyStatus', i + 1)}
                                className={formik.values.familyStatus === i + 1 ? 'active' : ''}
                            >
                                {familyStatus}
                            </li>
                        ))}
                    </ul>
                  </div>
                }
            </div>
            <div className="changeInfo__row">
                <label className="changeInfo__key">Кол-во детей:</label>
                <input className="changeInfo__input"
                       id="kidsCount"
                       name="kidsCount"
                       type="text"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.kidsCount}
                />
            </div>
            <div className="changeInfo__row">
                <label className="changeInfo__key">Имена детей:</label>
                <input className="changeInfo__input"
                       id="kidsNames"
                       name="kidsNames"
                       type="text"
                       onChange={formik.handleChange}
                       onBlur={formik.handleBlur}
                       value={formik.values.kidsNames}
                />
            </div>
            <div className="changeInfo__checkboxes-container">
                <label className="changeInfo__checkbox-label" htmlFor="sendSms">
                    <input className="changeInfo__checkbox"
                           type="checkbox"
                           id="sendSms"
                           name="sendSms"
                           formNoValidate={formik.values.sendSms === formik.initialValues.sendSms}
                           onChange={onChangeSmsHandler}
                    />{userInfo?.sendSms ? "Отписаться от sms-рассылки" : "Подписаться на sms-рассылку"}</label>
                <label className="changeInfo__checkbox-label" htmlFor="sendEmail">
                    <input className="changeInfo__checkbox"
                           type="checkbox"
                           id="sendEmail"
                           name="sendEmail"
                           onChange={onChangeEmailHandler}
                    />{userInfo?.sendEmail ? "Отписаться от email-рассылки" : "Подписаться на email-рассылку"}</label>
            </div>
            {!!formik.status && <div className="loginForm__error">{formik.status}</div>}
            {isLoading ? <Preloader /> :
            <div className="changeInfo__buttons-container">
                <button className="changeInfo__submit" disabled={formik.isSubmitting} type="submit">Сохранить
                    изменения
                </button>
                <button className="changeInfo__reset" type="reset" onClick={() => formik.resetForm()}>Отменить
                    изменения
                </button>
            </div>}
            <CancelSmsPopup formik={formik} setOpenGenericPopup={setOpenGenericPopup} openedPopup={openCancelSmsPopup}
                            closePopup={closeGenericPopup}/>
            <GenericPopup isOpened={openGenericPopup} onClose={closeGenericPopup}/>
            <SubscribeSmsPopup isOpened={openSubscribeSmsPopup} setOpenThanksPopup={setOpenThanksPopup}
                               onClose={closeGenericPopup}/>
            <ThanksPopup isOpened={openThanksPopup} onClose={closeGenericPopup}/>
            <CancelEmailPopup formik={formik} setOpenGenericPopup={setOpenGenericPopup}
                              openedPopup={openCancelEmailPopup} closePopup={closeGenericPopup}/>
        </form>
    );
};

export default ChangeInfo;