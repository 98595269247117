import React from 'react';
import close from "../../vendor/img/close.svg";
import whatsapp from "../../vendor/img/whatsapp-logo.svg";
import viber from "../../vendor/img/viber-logo.svg";
import telegram from "../../vendor/img/telegram-logo.svg";
import GetPasswordForm from "./GetPasswordForm/GetPasswordForm";
import './GetPasswordPopup.scss';

type TGetPasswordPopup = {
    onClose: () => void
    isOpened: boolean
    openLoginPopup: () => void
    phoneValue: string
    setPhoneValue: React.Dispatch<React.SetStateAction<string>>
}

const GetPasswordPopup:React.FC<TGetPasswordPopup> = ({onClose, isOpened, openLoginPopup, phoneValue, setPhoneValue}) => {

    const handleOpenLoginPopup = () => {
        onClose()
        openLoginPopup()
    }

    return (
        <div className={isOpened ? "loginPopup__layout loginPopup__layout_opened" : "loginPopup__layout"}>
            <div className="getPasswordPopup__content">
                <img alt="крестик" src={close} onClick={onClose} className="loginPopup__close" />
                <GetPasswordForm setPhoneValue={setPhoneValue} phoneValue={phoneValue} onClose={onClose} openLoginPopup={handleOpenLoginPopup} />
                <button onClick={handleOpenLoginPopup} className="loginPopup__white-button">Войти с паролем</button>
                <p className="loginPopup__text">Служба поддержки клиентов<br />Клуба Друзей Ассоциации Галерея Чижова</p>
                <p className="loginPopup__phone">+7(473)261-99-99</p>
                <div className="loginPopup__icons-container">
                    <a className="loginPopup__link" href="https://wa.me/89100419999"><img className="loginPopup__logo" alt="ватсап" src={whatsapp} /></a>
                    <a className="loginPopup__link" href="https://viber.click/79100419999"><img className="loginPopup__logo" alt="вайбер" src={viber} /></a>
                    <a className="loginPopup__link" href="https://t.me/gallerychizhov"><img className="loginPopup__logo" alt="телеграм" src={telegram} /></a>
                </div>
                <p className="loginPopup__phone-smaller">+7 910 041 99 99, call-center@glch.ru</p>
                <p className="loginPopup__text-smaller">Время работы Службы поддержки:<br />с 9.00 до 21.00, без перерыва и выходных</p>
            </div>
        </div>
    );
};

export default GetPasswordPopup;
