import React from 'react';
import {Link, useParams} from "react-router-dom";
import './ChoosenGallery.scss';
import Preloader from "../Preloader/Preloader";
import SwiperPhotoGallery from "../SwiperPhotoGallery/SwiperPhotoGallery";
import ChoosenGalleryGrid from "./ChoosenGalleryGrid";
import {formateDate} from "../../utils/formateDate";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import {useGetPhotoGalleryQuery} from "../../redux/photoGalleryApi";
import SwiperChoosenGallery from "../SwiperChoosenGallery/SwiperChoosenGallery";

type TChoosenGallery = {
    windowWidth: number
}

const ChoosenGallery: React.FC<TChoosenGallery> = ({ windowWidth }) => {
    const {photoLink} = useParams();
    const {currentData: gallery, isError, isFetching} = useGetPhotoGalleryQuery(photoLink)

    if (isFetching) return <Preloader />

    if (isError) return <NotFoundPage />

    return (
        <>
            <div className="choosenGallery">
                <div className="bread-crumbs">
                    <Link to="/" className="bread-crumbs-link">Главная</Link>
                    <Link to="/photos" className="bread-crumbs-link">Фотогалерея</Link>
                    <Link to={`/photos/`} className="bread-crumbs-link">Фотоотчет</Link>
                </div>

                <div className="choosenGallery__block">
                    <div className="choosenGallery__gallery">
                        <div className="choosenGallery__galleryInfo">
                            {gallery?.date && <p className="choosenGallery__galleryDate">{formateDate(gallery?.date)}</p>}
                            <p className="choosenGallery__galleryName">{gallery?.name}</p>
                        </div>
                        {windowWidth > 1200 ? <SwiperChoosenGallery key={gallery?.slug} photos={gallery?.pictures} /> :
                        <ChoosenGalleryGrid key={gallery?.slug} photos={gallery?.pictures} />}
                    </div>
                    <div className="choosenGallery__otherGalleriesBlock">
                        <h2 className="choosenGallery__otherGalleriesTitle">Фотогалерея</h2>
                        <SwiperPhotoGallery />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChoosenGallery;
