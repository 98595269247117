import React, {Dispatch, SetStateAction} from 'react';
import close from "../../../../vendor/img/close.svg";

type TSubscribeSmsPopup = {
    onClose: () => void
    isOpened: boolean
    setOpenThanksPopup: Dispatch<SetStateAction<boolean>>

}

const SubscribeSmsPopup: React.FC<TSubscribeSmsPopup> = ({onClose, isOpened, setOpenThanksPopup}) => {
    const acceptHandler = () => {
            onClose()
            setOpenThanksPopup(true)
    }

    return (
        <div className={isOpened ? "genericPopup genericPopup_visible" : "genericPopup"}>
            <div className="genericPopup__content">
                <img onClick={onClose} alt="крест" src={close} className="genericPopup__close"/>
                <h3 className="genericPopup__title">Я хочу получать информацию о скидках, акциях и мероприятиях
                    Ассоциации Галерея Чижова</h3>
                <button onClick={acceptHandler} type="button" className="genericPopup__button">Подтвердить</button>
            </div>

        </div>
    );
};

export default SubscribeSmsPopup;