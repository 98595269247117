import './LandingSpring.scss'

interface IPopup {
  active: boolean,
  setActive: (active: boolean)=>void
}

const Popup: React.FC<IPopup> = ({active, setActive}) => {
  const closePopup = () =>{
    document.querySelector('.spring-wrapper')?.scrollTo(0, 0);
    setActive(false)
  }
  return <div className={active ? 'spring-popup active' : 'spring-popup'} onClick={closePopup}>
    <div className="popup-content" onClick={e=>e.stopPropagation()}>
      <h3>Спасибо за участие!</h3>
      <p>После модерации чеков на номер телефона, указанный в анкете, придет сообщение с номером виртуальной карты "Клуба друзей" и количеством купонов для участия в Розыгрыше.</p>
      <a href="#" onClick={e=>{
        e.preventDefault();
        closePopup();
      }}>На главную</a>
      <button type="button" onClick={closePopup}>&#x2716;</button>
    </div>
  </div>
}

export default Popup;