import {Outlet} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import Header from "../Header/Header";
import HeaderMobile from "../HeaderMobile/HeaderMobile";
import SignEmail from "../SignEmail/SignEmail";
import BurgerMenuMobile from "../BurgerMenuMobile/BurgerMenuMobile";
import Footer from "../Footer/Footer";
import FooterMobile from "../FooterMobile/FooterMobile";
import Search from "../Search/Search";
import ChoosePopup from "../ChoosePopup/ChoosePopup";
import LoginPopup from "../LoginPopup/LoginPopup";
import GetPasswordPopup from "../GetPasswordPopup/GetPasswordPopup";
import TgPopup from "../TgPopup/TgPopup";
import TgPopupPreview from "../TgPopupPreview/TgPopupPreview";

type TLayout = {
    windowWidth: number,
    isChoosePopupOpened: boolean,
    setIsChoosePopupOpened: (isOpen: boolean) => void
}

const Layout: React.FC<TLayout> = ({windowWidth, isChoosePopupOpened, setIsChoosePopupOpened}) => {
    const [isBurgerOpened, setIsBurgerOpened] = useState<boolean>(false);
    const [isSearchOpened, setIsSearchOpened] = useState<boolean>(false);
    const [isLoginPopupOpened, setIsLoginPopupOpened] = useState<boolean>(false);
    const [isGetPasswordPopupOpened, setIsGetPasswordPopupOpened] = useState<boolean>(false);
    const [phoneValue, setPhoneValue] = useState<string>('')
    const [isTgPopupOpened, setIsTgPopupOpened] = useState(false)

    useEffect(() => {
        if (windowWidth < 700) {
            setTimeout(() => {
                setIsTgPopupOpened(true)
            }, 60000)
        }
    }, [])

    const handleCloseAllPopups = () => {
        setIsChoosePopupOpened(false)
        setIsLoginPopupOpened(false)
        setIsGetPasswordPopupOpened(false)
    }

    const handleSearchClick = () => {
        setIsSearchOpened(!isSearchOpened)
    }

    const handleOpenGetPasswordPopup = () => {
        setIsGetPasswordPopupOpened(true)
    }

    const handleOpenLoginPopup = () => {
        setIsLoginPopupOpened(true)
    }

    const handleChoosePopupClick = () => {
        setIsChoosePopupOpened(true)
    }

    const handleMenuClick = () => {
        setIsBurgerOpened(!isBurgerOpened);
    };

    const handleOpenTgPopup = () => {
        setIsTgPopupOpened(true)
    }

    const handleCloseTgPopup = () => {
        setIsTgPopupOpened(false)
    }

    return <>
        {windowWidth > 1260 ?
            <Header handleLoginClick={handleChoosePopupClick} handleSearchClick={handleSearchClick}/> :
            <HeaderMobile onOpenBurger={handleMenuClick}/>}
        <Outlet/>
        {windowWidth > 1260 ? <Footer/> : <FooterMobile/>}
        <SignEmail/>
        {(windowWidth < 750 && !isTgPopupOpened) && <TgPopupPreview openPopup={handleOpenTgPopup}/>}
        <BurgerMenuMobile handleLoginClick={handleChoosePopupClick}
                          handleSearchClick={handleSearchClick}
                          isBurgerOpened={isBurgerOpened}
                          onOpenBurger={handleMenuClick}/>
        <Search isOpened={isSearchOpened} handleClick={handleSearchClick}/>
        <ChoosePopup openLoginPopup={handleOpenLoginPopup} onClose={handleCloseAllPopups}
                     isOpened={isChoosePopupOpened}/>
        <LoginPopup phoneValue={phoneValue} setPhoneValue={setPhoneValue} onClose={handleCloseAllPopups}
                    openGetPasswordPopup={handleOpenGetPasswordPopup} isOpened={isLoginPopupOpened}/>
        <GetPasswordPopup phoneValue={phoneValue} setPhoneValue={setPhoneValue} onClose={handleCloseAllPopups}
                          isOpened={isGetPasswordPopupOpened} openLoginPopup={handleOpenLoginPopup}/>
        <TgPopup isOpened={isTgPopupOpened} onClose={handleCloseTgPopup}/>
    </>
}

export default Layout;
