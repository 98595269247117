import React from 'react';
import {Link} from "react-router-dom";
import {baseUrl} from "../../vendor/constants";
import './SwiperPhotoGallery.scss';
import {formateDate} from "../../utils/formateDate";
import {TPhotoGallery} from "../../redux/types";

type TSlidePhotoGallery = {
    gallery: TPhotoGallery
}

const SlidePhotoGallery: React.FC<TSlidePhotoGallery> = ({gallery}) => {

    const mainImage = gallery.pictures.find(pic => pic.main === 1)
    const renderMainImage = mainImage ? mainImage : gallery.pictures[0]
    const otherImages = gallery.pictures.filter(pic => pic.image !== renderMainImage.image).slice(0, 4)

    return (
        <>
            <div className="swiperPhotoGallery__images-container">
                <div className="swiperPhotoGallery__main-image">
                    <img src={baseUrl + renderMainImage.image.thumb} className="swiperPhotoGallery__img"/>
                </div>
                <div className="swiperPhotoGallery__other-images">
                    {otherImages.map(image => {
                        return (
                            <div key={image.image.thumb} className="swiperPhotoGallery__other-image">
                                <img src={baseUrl + image.image.thumb} className="swiperPhotoGallery__other-img"/>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="swiperPhotoGallery__description-container">
                <h3 className="swiperPhotoGallery__title">{gallery.name}</h3>
                <p className="swiperPhotoGallery__date">{formateDate(gallery.date)}</p>
            </div>
            <Link className="swiper__link swiperPhotoGallery__link" to={`/photos/${gallery.slug}`}>Подробнее</Link>
        </>
    );
};

export default SlidePhotoGallery;
