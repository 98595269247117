import okey from './assets/gifts-block/gift-logos/okey.png'
import mvideo from './assets/gifts-block/gift-logos/mvideo.png'
import sportmaster from './assets/gifts-block/gift-logos/sportmaster.png'
import snezhnaya from './assets/gifts-block/gift-logos/snezhnaya.png'
import gloriajeans from './assets/gifts-block/gift-logos/gloriajeans.png'
import letoile from './assets/gifts-block/gift-logos/letoile.png'
import waikiki from './assets/gifts-block/gift-logos/waikiki.png'
import leonardo from './assets/gifts-block/gift-logos/leonardo.png'
import befree from './assets/gifts-block/gift-logos/befree.png'
import eldorado from './assets/gifts-block/gift-logos/eldorado.png'
import familia from './assets/gifts-block/gift-logos/familia.png'
import deFacto from './assets/gifts-block/gift-logos/deFacto.png'
import DNS from './assets/gifts-block/gift-logos/DNS.png'
import rendezvous from './assets/gifts-block/gift-logos/rendezvous.png'
import kari from './assets/gifts-block/gift-logos/kari.png'
import boss from './assets/moda-block/moda-logos/boss.png'
import emporio from './assets/moda-block/moda-logos/emporio.png'
import escada from './assets/moda-block/moda-logos/escada.png'
import liujo from './assets/moda-block/moda-logos/liujo.png'
import trussardi from './assets/moda-block/moda-logos/trussardi.png'
import marcopolo from './assets/moda-block/moda-logos/marcopolo.png'
import karl from './assets/moda-block/moda-logos/karl.png'
import henderson from './assets/moda-block/moda-logos/henderson.png'
import it from './assets/moda-block/moda-logos/it.png'
import tous from './assets/moda-block/moda-logos/tous.png'
import guess from './assets/moda-block/moda-logos/guess.png'
import lacoste from './assets/moda-block/moda-logos/lacoste.png'
import lime from './assets/moda-block/moda-logos/lime.png'
import natgo from './assets/moda-block/moda-logos/natgo.png'
import lichi from './assets/moda-block/moda-logos/lichi.png'
import worldClass from './assets/familly-block/familly-logos/worldClass.png'
import sinema from './assets/familly-block/familly-logos/sinema.png'
import warpoint from './assets/familly-block/familly-logos/warpoint.png'
import scandy from './assets/familly-block/familly-logos/scandy.png'
import dreamaero from './assets/familly-block/familly-logos/dreamaero.png'
import museum from './assets/familly-block/familly-logos/museum.png'
import avatar from './assets/familly-block/familly-logos/avatar.png'
import detskiy from './assets/familly-block/familly-logos/detskiy.png'
import mirkubikov from './assets/familly-block/familly-logos/mirkubikov.png'
import acoola from './assets/familly-block/familly-logos/acoola.png'
import chicco from './assets/familly-block/familly-logos/chicco.png'
import originalMarines from './assets/familly-block/familly-logos/originalMarines.png'
import moriki from './assets/familly-block/familly-logos/moriki.png'
import kotofey from './assets/familly-block/familly-logos/kotofey.png'
import moygorod from './assets/familly-block/familly-logos/moygorod.png'
import balagan from './assets/gastro-block/gastro-logos/balagan.png'
import twentythree from './assets/gastro-block/gastro-logos/twentythree.png'
import grillpark from './assets/gastro-block/gastro-logos/grillpark.png'
import phobo from './assets/gastro-block/gastro-logos/phobo.png'
import gagawa from './assets/gastro-block/gastro-logos/gagawa.png'
import coffeelike from './assets/gastro-block/gastro-logos/coffeelike.png'
import tomato from './assets/gastro-block/gastro-logos/tomato.png'
import burgerking from './assets/gastro-block/gastro-logos/burgerking.png'
import kfc from './assets/gastro-block/gastro-logos/kfc.png'
import burgerClub from './assets/gastro-block/gastro-logos/burgerClub.png'
import vkusno from './assets/gastro-block/gastro-logos/vkusno.png'
import bulldog from './assets/gastro-block/gastro-logos/bulldog.png'
import tashir from './assets/gastro-block/gastro-logos/tashir.png'
import groot from './assets/gastro-block/gastro-logos/groot.png'
import fixcoffee from './assets/gastro-block/gastro-logos/fixcoffee.png'
import choco from './assets/gastro-block/gastro-logos/choco.png'
import makecoffee from './assets/gastro-block/gastro-logos/makecoffee.png'
import buntaro from './assets/gastro-block/gastro-logos/buntaro.png'
import hotfix from './assets/gastro-block/gastro-logos/hotfix.png'
import dreamcoffee from './assets/gastro-block/gastro-logos/dreamcoffee.png'

export const giftsLogos = [
  { path: okey, discount: 'до 95%' }, { path: mvideo, discount: 'до 50%' }, { path: sportmaster, discount: 'до 30%' }, { path: snezhnaya, discount: 'до 50%' },
  { path: gloriajeans, discount: 'до 80%' }, { path: letoile, discount: 'до 50%' }, { path: waikiki, discount: 'до 50%' }, { path: leonardo, discount: 'до 80%' },
  { path: befree, discount: 'до 70%' }, { path: eldorado, discount: 'до 50%' }, { path: familia, discount: 'до 85%' }, { path: deFacto, discount: 'до 70%' },
  { path: DNS, discount: 'до 50%' }, { path: rendezvous, discount: 'до 70%' }, { path: kari, discount: 'до 70%' }
]

export const modaLogos = [
  boss, emporio, escada, liujo, trussardi, marcopolo,
  karl, henderson, it, tous, guess, lacoste, lime,
  natgo, lichi
]

export const famillyLogos = [
  worldClass, sinema, warpoint, scandy, dreamaero,
  museum, avatar, detskiy, mirkubikov, acoola,
  chicco, originalMarines, moriki, kotofey, moygorod
]

export const gastroLogos = [
  balagan, twentythree, grillpark, phobo, gagawa,
  coffeelike, tomato, burgerking, kfc, burgerClub,
  vkusno, bulldog, tashir, groot, fixcoffee,
  choco, makecoffee, buntaro, hotfix, dreamcoffee
]